const EditPenIcon = ({fill, dimensions}) => {
    return <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4988_9951)">
            <path d="M3.52456 12.1135L5.29956 13.874L15.8626 3.22401L14.0876 1.4635L3.52456 12.1135Z"
                stroke="#08616a" strokeWidth="2" strokeLinejoin="round" />
            <path d="M1.77131 15.6564L5.29957 13.8741L3.52456 12.1136L1.77131 15.6564Z" stroke="#08616a"
                strokeWidth="2" strokeLinejoin="round" />
            <path d="M14.1021 4.99902L12.327 3.23852" stroke="#08616a" strokeWidth="2"
                strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_4988_9951">
                <rect width="3.75" height="20" fill="white"
                    transform="translate(2.66248 17.4204) rotate(-135.235)" />
            </clipPath>
        </defs>
    </svg>
}

export default EditPenIcon