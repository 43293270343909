import ReactDOM from "react-dom";

const LoadingWrapperV2 = ({text}) => {
    return <>
    {ReactDOM.createPortal(
        <div className='preparing_restaurant_v2'>
            <div className='preparing_restaurant_backdrop'>
                <div className='preparing_restaurant_container'>
                    <div className='preparing_restaurant_loader'></div>
                    <div className='preparing_restaurant_message'>{text}</div>
                </div>
            </div>
        </div>,
        document.getElementById("overlay-root")
    )}
    </>
}

export default LoadingWrapperV2