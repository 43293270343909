import classes from "./Option.module.css";
import placeholderImage from '../../../assets/MSC_placeholder.jpg'
import CheckMarkIcon from '../../../icons/checkmarkicon'
import { formatNumberDecimal } from "../../../scripts/helpers";
import OptionTooltip from "./OptionTooltip";

const Option = ({ option, setOption, limitReached, choiceStyle }) => {

    let price = null
    if (option.cost > 0) {
        price = option.cost
    }

    return (
        <div className={classes.option_container}>
            <button
                onClick={setOption.bind(this, option.id)}
                className={`${classes.option} ${option.itemSelected ? classes.active : ""} ${choiceStyle ? '' : classes.option_simple}`}
                disabled={(limitReached && !option.itemSelected) ? true : false}
            >
                <div className={classes.image_container}>
                    <div className={classes.image_container_inner}>
                        {option.image && <img src={option.image} alt={option.name}/>}
                        {!option.image && <img src={placeholderImage} alt={option.name}/>}
                    </div>
                    <div className={classes.check_mark}>
                        <CheckMarkIcon />                        
                    </div>
                </div>
                {!choiceStyle && <div className={classes.check_mark}>
                    <CheckMarkIcon />                    
                </div>}
                <div className={classes.option_title}>
                    {option.name}
                    {price && <div className={classes.option_price}>${formatNumberDecimal(option.cost)}</div>}
                </div>
            </button>
            {option.metadata && option.metadata.filter(item => item.key === "Tooltip").map(item => (
                <OptionTooltip data={item} key={item.key}/>
            ))}
            {/* <div className={`${classes.option_title} ${classes.option_title_controller}`}>
                <button>{option.name}</button>
                {option.metadata && option.metadata.filter(item => item.key === "Tooltip").map(item => (
                    <OptionTooltip data={item} key={item.key}/>
                ))}
            </div> */}
            {/* {price && <div className={classes.option_price}>${formatNumberDecimal(option.cost)}</div>} */}
            
        </div>
    );
};

export default Option;
