import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfoComponent from '../common/InfoComponent'
import { variousActions } from '../../store/various'
import { getAuthToken } from '../../scripts/auth'
import { reorderFromFavoriteOrPrevious, reorderFromPreviousOrder } from '../../scripts/fetchApi'
import { cartActions } from '../../store/cart'
import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'

import classes from './OrderAgain.module.css'
import { getGenericToken } from '../../scripts/helpers'

const OrderAgain = ({item, tag}) => {
    const dispatch =  useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState (false)

    // const token = getAuthToken()
    // const token = sessionStorage.getItem('token')

    const token = getGenericToken()

    const thisOrder = item
    

    let locationStore = useSelector(state => state.location.location)

    const orderAgainHandler = async () => {
        
        if (locationStore.id !== thisOrder.vendorid) {

            setShowModal(true)
            return;
        }

        // if location is the same and no items in cart order again! 
        orderAgain()
        
    }

    const orderAgain = async () => {
        setIsLoading(true)
        //  remove current cart if any

        sessionStorage.removeItem('currentCart')
        dispatch(cartActions.addCart(null))

        if (tag) {
            const reorder = await reorderFromPreviousOrder(token, {
                order_id: thisOrder.id
            })

            if (reorder.id) {
                // success TOTEST
                dispatch(cartActions.addCart(reorder))
                // setStorageItem('currentCart', reorder)
                setSessionStorageItem('currentCart', reorder)
                dispatch(cartActions.setCartSidebarStep("order"))
                dispatch(cartActions.setCartSidebarState())
            } else {
                dispatch(variousActions.setError(reorder))
            }
        } else {
            const req = {
                favorite_id: item.id,
                ignore_unavailable_products: 1
            }
            const reorder = await reorderFromFavoriteOrPrevious(token, req)    

            if (reorder.id) {
                dispatch(cartActions.addCart(reorder))
                // setStorageItem('currentCart', reorder)
                setSessionStorageItem('currentCart', reorder)
                dispatch(cartActions.setCartSidebarStep("order"))
                dispatch(cartActions.setCartSidebarState()) 
            } else {
                dispatch(variousActions.setError(reorder))
            }
        }

        
        setIsLoading(false)
    }

    const closeModalhandler = () => {
        setShowModal(false)
    }

    const acceptButton = <>
        {isLoading && <div className="btn"><span className='loader'></span></div>}
        {!isLoading && <button className="btn" onClick={orderAgain}>Order again</button>}
    </>
    return <>
        {!isLoading && <button className='btn btn-small' onClick={orderAgainHandler}>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z"/></svg>
            Order again
        </button>}
        {isLoading && <div className='btn btn-small'><span className='loader small'></span></div>}


        {showModal && <InfoComponent cancel={closeModalhandler} accept={acceptButton} >
            <div className={classes.info_container}>
                <p>Do you want to clear your existing cart at <span>{locationStore.name}</span> and reorder your recent order from <span>{thisOrder.vendorname}</span>?</p>  
            </div>
        </InfoComponent>}
    </>
}

export default OrderAgain