import { useLoaderData, Await } from "react-router-dom"
import { Suspense } from "react"

import EditAccountMain from "../components/account/Edit"
import LoadingWrapperV2 from "../components/common/LoadingWrapperV2"

const Edit = () => {
    const data = useLoaderData() 
     
    return <Suspense fallback={<LoadingWrapperV2 text="Loading Account"/>}>
        <Await resolve={data.data}>
            {() => <>
                <EditAccountMain/>
            </>}
        </Await>
    </Suspense>
}

export default Edit