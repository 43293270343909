import { useEffect, useRef } from "react";

const AddressInput = ({inputError, setError, setValue, setAddressDetails}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        let autocomplete;
        function getPostCode(place){
            for (var i = 0; i < place.address_components.length; i++) {
                for (var j = 0; j < place.address_components[i].types.length; j++) {
                  if (place.address_components[i].types[j] == "postal_code") {
                    return place.address_components[i].long_name;
                  }
                }
              }
        }
        autocomplete = new window.google.maps.places.Autocomplete((inputRef.current), {
            types: ['address'],
            componentRestrictions: {country: "us"} 
        });

        window.google.maps.event.addListener(autocomplete, 'place_changed', function () {

            autocomplete.getPlace();   
            const address = autocomplete.getPlace()
            let postal_code = false
            let city = false
            let street_number = false
            let street = false
            let addrressDetails = {
                postal_code: '',
                city: '',
                street_number: '',
                street: '',
                full_address: ''
            }
            address.address_components.forEach(element => {
                element.types.forEach(item => {
                    if (item === "postal_code") {
                        postal_code = true
                        addrressDetails.postal_code = element.long_name
                    }
                    if(item === "locality") {
                        city = true
                        addrressDetails.city = element.long_name
                    }
                    if(item === "street_number") {
                        street_number = true
                        addrressDetails.street_number = element.long_name
                    }
                    if(item === "route") {
                        street = true
                        addrressDetails.street = element.long_name
                    }
                })
            });

            if (postal_code && city && street_number && street) {
                setError(null)
                setValue(inputRef.current.value)
                addrressDetails.full_address = inputRef.current.value
                setAddressDetails(addrressDetails)
            } else {
                setError('Not a complete address!')
            }
        });
    }, [])

    return <input id='searchAddress' type='text' placeholder='Your address' ref={inputRef} className={`${inputError ? 'error' : ''}`} required />

}

export default AddressInput