import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import classes from './Cart.module.css'
import Signin from "../authentication/Signin";
import Signup from "../authentication/Signup"
import { useNavigate } from 'react-router-dom'
import { cartActions } from "../../store/cart";
import { getItemFromStorage } from "../../scripts/localStorage";
import { isEmail, isEmpty, formatPhone } from "../../scripts/helpers";
import { setStorageItem } from "../../scripts/localStorage";
import CartOloAuthCheckBox from "./CartOloAuthCheckBox";




const CartUserInfo = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user)


    const guest = getItemFromStorage('guestUser')

    const [authType, setAuthType] = useState('guest')
    const [formInputsValidity, setFormInputsValidity] = useState({
        email: true,
        firstName: true,
        lastName: true,
        phone: true
    })

    const fNameInputRef = useRef(null)
    const lNameInputRef = useRef(null)
    const emailInputRef = useRef(null)
    const phoneInputRef = useRef(null)

    const isAuthenticated = useSelector(state => state.auth.user)

    const checkoutHandler = (event) => {
        event.preventDefault()

        const enteredFirstName = fNameInputRef.current.value
        const enteredLastName = lNameInputRef.current.value
        const enteredEmail = emailInputRef.current.value
        const enteredPhone = phoneInputRef.current.value

        const enteredEmailisValid = !isEmpty(enteredEmail) && isEmail(enteredEmail)
        const enteredFirstNameisValid = !isEmpty(enteredFirstName)
        const enteredLastNameisValid = !isEmpty(enteredLastName)
        const enteredPhoneisValid = !isEmpty(enteredPhone)

        setFormInputsValidity({
            email: enteredEmailisValid,
            firstName: enteredFirstNameisValid,
            lastName: enteredLastNameisValid,
            phone: enteredPhoneisValid
        })

        const formIsValid = enteredEmailisValid && enteredFirstNameisValid && enteredLastNameisValid && enteredPhoneisValid

        if (formIsValid) {
            setStorageItem('guestUser', {
                firstName: enteredFirstName,
                lastName: enteredLastName,
                email: enteredEmail,
                phone: enteredPhone
            })
            // advance()
            dispatch(cartActions.setCartSidebarStep("checkout"))
        } 
    }

    const switchAuthType = (e) => {
        setAuthType(e)
    } 

    const userAuthContinueHandler = () => {
        // advance()
        dispatch(cartActions.setCartSidebarStep("checkout"))
    }

    const changeInfoHandler = () => {
        dispatch(cartActions.setCartSidebarState())
        navigate('/account/edit')
    }

    const [phoneValue, setPhoneValue] = useState(guest ? guest.phone : null)
    const phoneFormatHandler = (e) => {    
        setPhoneValue(formatPhone(e.target.value))
    }
 
    return <>
        {isAuthenticated && <>
            <h3>Account Details</h3>
            <div className={classes.user_info}><span>Name:</span>{user.name}</div>
            <div className={classes.user_info}><span>Email:</span>{user.email}</div>
            <div className={classes.user_info}><span>Phone:</span>{formatPhone(user.phone)}</div>
            <div className={classes.user_info}>
                <button onClick={changeInfoHandler} className="btn">Change Info</button>
            </div>
            <div className={classes.cart_footer}>
                <button type="submit" className="btn" onClick={userAuthContinueHandler}>Continue to Payment</button>
            </div>
        </>}
        {!isAuthenticated && <>
            <div className={classes.info_pannel_header}>
                <button className={`btn ${authType === 'guest' && classes.btn_active}`} onClick={switchAuthType.bind(this, 'guest')}>
                    Guest Checkout
                </button>
                {/* <button className={`btn ${authType === 'sign' && classes.btn_active}`} onClick={switchAuthType.bind(this, 'sign')}>
                    Sign In
                </button>
                <button className={`btn ${authType === 'signup' && classes.btn_active}`} onClick={switchAuthType.bind(this, 'signup')}>
                    Join Rewards
                </button> */}
            </div>

            {authType === 'guest' && <form onSubmit={checkoutHandler}>
                <div className={`form-fields-container ${classes.guest_form}`}>
                    <div className="form-controller-group">
                        <div className="form-controller">
                            <label className="label" htmlFor="guest_first_name">First Name</label>
                            <input ref={fNameInputRef} type="text" id="guest_first_name" placeholder="Johnathan" required className={`${!formInputsValidity.firstName ? 'error' : null}`} defaultValue={guest ? guest.firstName : null}/>
                            {!formInputsValidity.firstName && <div className='error-message'>Please enter a name!</div>}
                        </div>
                        <div className="form-controller">
                            <label className="label" htmlFor="guest_last_name">Last Name</label>
                            <input ref={lNameInputRef} type="text" id="guest_last_name" placeholder="Hale" required className={`${!formInputsValidity.lastName ? 'error' : null}`} defaultValue={guest ? guest.lastName : null}/>
                            {!formInputsValidity.lastName && <div className='error-message'>Please enter a name!</div>}
                        </div>
                    </div>
                    <div className="form-controller">
                        <label className="label" htmlFor="guest_email">Email Address</label>
                        <input ref={emailInputRef} type="text" id="guest_email" placeholder="johnathanhale@mail.com" required className={`${!formInputsValidity.email ? 'error' : null}`}  defaultValue={guest ? guest.email : null}/>
                        {!formInputsValidity.email && <div className='error-message'>Please enter a valid email!</div>}
                    </div>
                    <div className="form-controller">
                        <label className="label" htmlFor="guest_phone">Phone Number</label>
                        <input ref={phoneInputRef} type="text" id="guest_phone" placeholder="123-456-7890" required className={`${!formInputsValidity.phone ? 'error' : null}`}  onChange={phoneFormatHandler} defaultValue={phoneValue}/>
                        {!formInputsValidity.phone && <div className='error-message'>Please enter a phone number</div>}
                        {/* <label className="label" htmlFor="guest_phone">Phone Number</label>
                        <input ref={phoneInputRef} type="text" id="guest_phone" placeholder="123-456-7890" required className={`${!formInputsValidity.phone ? 'error' : null}`} maxLength="11" />
                        {!formInputsValidity.phone && <div className='error-message'>Please enter a phone number</div>} */}
                    </div>
                </div>
                {/* <CartOloAuthCheckBox /> */}
                <div className={classes.cart_footer}>
                    <button type="submit" className="btn">Continue to Payment</button>
                </div>
            </form>}

            {authType === 'sign' && <Signin position={'cart'}/>}
            {authType === 'signup' && <Signup position={'cart'}/>}
        </>}
    </>
}

export default CartUserInfo