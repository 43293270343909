import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFavoriteLocation } from '../../scripts/fetchApi';
import { getAuthToken } from '../../scripts/auth';
import { variousActions } from '../../store/various';

const SetFavoriteLocation = ( {data}) => {
    const dispatch = useDispatch()
    const [loadingFav, setLoadingFav] = useState(false)
    const setFavoriteLocationHandler = async () => {
        setLoadingFav(true)

        const favloc= await setFavoriteLocation(getAuthToken(), data.id) //

        if (favloc.message) {
            dispatch(variousActions.setError(favloc))
        } else {
            dispatch(variousActions.setError({
                message: 'Location set as favorite!',
                type: 'success'
            }))
        }

        setLoadingFav(false)
    }


    return <>
        {loadingFav && <span className='btn-icon'><span className="loader dark-raspberry"></span></span>}
        {!loadingFav && <button className='btn-icon' title='set favorite location' onClick={setFavoriteLocationHandler}>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg>
            <span className='sr-only'>set as favorite</span>
        </button>}
    </>
}

export default SetFavoriteLocation