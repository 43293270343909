import { useDispatch } from 'react-redux'
import { getAuthToken } from '../../scripts/auth'
import { removeFavoriteOrder } from '../../scripts/fetchApi'
import { variousActions } from '../../store/various'
import { useState } from 'react'

const RemoveFavoriteOrder = ({item, removeitem}) => {
    const dispatch = useDispatch()
    const [isloading, setIsLoading] = useState(false)
    const token = getAuthToken()
    const removeFavoriteHandler = async () => {
        setIsLoading(true)

        const removeFav = await removeFavoriteOrder(token, item.id)

        if (removeFav.message) {
            dispatch(variousActions.setError(removeFav))
        } else {
            removeitem(true)
        }

        setIsLoading(false)
    }  
    return <>
        {!isloading && <button className='btn btn-small' onClick={removeFavoriteHandler}>
            <svg viewBox="-2 0 18 18" xmlns="http://www.w3.org/2000/svg" aria-label="Delete" role="img"><path d="M.996 15.504a1.5 1.5 0 001.501 1.501h9.006a1.5 1.5 0 001.5-1.5V4.996H.997v10.507zM9.502 7.5a.5.5 0 111 0v7.004a.5.5 0 11-1 0V7.5zM6.5 7.5a.5.5 0 011 0v7.004a.5.5 0 11-1 0V7.5zm-3.002 0a.5.5 0 011 0v7.004a.5.5 0 11-1 0V7.5zm10.006-5.503H9.752l-.294-.585a.75.75 0 00-.673-.416H5.211a.742.742 0 00-.669.416l-.294.585H.496a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13.008a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"></path></svg>
            <span>Remove</span>
        </button>}
        {isloading && <span className='btn btn-small'><span className='loader small'></span></span>}
    </>
}

export default RemoveFavoriteOrder