import { useLoaderData, Await, redirect, defer } from "react-router-dom"
import { Suspense } from "react"
import AccountLanding from "../components/account/AccountLanding"
import LoadingWrapperV2 from "../components/common/LoadingWrapperV2"
import { getuserInfo } from "../scripts/fetchApi"

const Account = () => {
    const data = useLoaderData() 
     
    return <Suspense fallback={<LoadingWrapperV2 text="Loading Account"/>}>
        <Await resolve={data.data}>
            {() => <>
                <AccountLanding/>
            </>}
        </Await>
    </Suspense>
}

export default Account

async function loadData() {
    const token = sessionStorage.getItem('token')
    // redirect all to homepage 
    return redirect('/')
    if (token) {
        console.log('token::: ', token)
        const user = await getuserInfo(token)   
        console.log('user:::: ', user)       
        if (!user.message) {
            return user
        } else {
            sessionStorage.removeItem("token")
            return redirect('/signin')
            // return null
        }
    }

    const olotoken = sessionStorage.getItem('olotoken')

    if (olotoken) {
        const user = await getuserInfo(olotoken, 'oloauth') 
        
        console.log('olo user::: ', user)
        if (!user.message) {
            const formatUser = {
                first_name: user.firstname ? user.firstname : '',
                last_name: user.lastname ? user.lastname : '',
                name: (user.firstname ? user.firstname : '') + ' ' + (user.lastname ? user.lastname : ''),
                email: user.emailaddress,
                phone: user.phone ? user.phone : '',
            }    
            return user
        } else {
            sessionStorage.removeItem("token")
            return redirect('/signin')
            // return null
        } 

    }

    return redirect('/signin')
    // return null
}

export async function loader({ request, params}) {

   
    return defer({
        data: loadData()
    })    
}