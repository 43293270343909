import Card from '../fragments/Card'
import classes from './FavoriteItem.module.css'
import { useDispatch } from 'react-redux'
import { cartActions } from '../../store/cart'
import { deleteUserFavoriteLocation, cartSetDeliveryMethod, createCart, getMenuFromLocationId, getLocationsById } from '../../scripts/fetchApi'
import { getAuthToken } from '../../scripts/auth'
import { useState } from 'react'
import { variousActions } from '../../store/various'
import { virtualCartActions } from '../../store/virtualCart'
import { locationActions } from '../../store/location'
import { deleteItemFromSessionnStorage, deleteItemFromStorage, setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'
import { getGenericToken } from '../../scripts/helpers'

const FavoriteLoc = ({item, removeLoc}) => {
    const dispatch =  useDispatch()
    const [loadingSetLoc, setLoadingSetLoc] = useState(false)
    const [loading, setLoading] = useState(false)
    const deliveryType = 'pickup'

   // const token = getAuthToken()
    // const token = sessionStorage.getItem('token')
    const token = getGenericToken()

    const removeFavoriteHandler = async (e) => {
        setLoading(true)
        const removeFav = await deleteUserFavoriteLocation(token, item.vendorid)
        removeLoc(true)
        setLoading(false)
    }

    const fetchData = async (cartMethod, data) => {
        const menu = await getMenuFromLocationId(item.vendorid)
        //first delete cart
        // deleteItemFromStorage('currentCart')
        deleteItemFromSessionnStorage('currentCart')
        // dispatch(cartActions.clearCart())
        dispatch(cartActions.addCart(null))
        dispatch(virtualCartActions.clearVirtualCart())
        dispatch(locationActions.setLocationItem(data))
        dispatch(locationActions.setMenuItem(menu))

        dispatch(locationActions.setVirtualDeliveryType(deliveryType))
        setStorageItem('currentLocationIdMarble', {
            currentLocationIdMarble: data.id,
            currentLocationSlug: data.slug
        }, null) 
        setStorageItem('currentLocationMarble', {
            currentLocationMarble: data,
            deliveryType: deliveryType,
            time: 'asap',
        }, null) 
        setStorageItem('currentMenuMarble', {
            menu: menu
        }, null) 

        dispatch(cartActions.addCart(cartMethod))
        // setStorageItem('currentCart', cartMethod)
        setSessionStorageItem('currentCart', cartMethod)
                
    } 

    
    const setLocationhandler = async () => {
        // new method to set location with cart.
        setLoadingSetLoc(true);
        const loc = await getLocationsById(item.vendorid)
        if (loc.id) {
            const newCart = await createCart({restaurant_id: item.vendorid})
            if (newCart.id) {
                const deliveryCartInfo = {
                    delivery_mode: deliveryType,
                    time: 'asap',
                    schedule_date: null,
                    schedule_time: null
                }           
                //set delivery method
                const cartMethod = await cartSetDeliveryMethod(newCart.id, deliveryCartInfo)
    
                //set vehicle info if curbside  
                fetchData(cartMethod, loc)
                dispatch(variousActions.setError({
                    message: `Location set as ${item.vendorname}`,
                    type: 'success'
                }))
                
                
            } else {
                dispatch(variousActions.setError(newCart))
            }
        } else {
            dispatch(variousActions.setError(loc))
        }
        setLoadingSetLoc(false);
    }

    return <Card direction="column">
        <div className={classes.item_title}>{item.vendorname}</div>
        <div className={classes.controls}>
            {/* set location as favorite  */}
            {!loadingSetLoc && <button className='btn btn-small' onClick={setLocationhandler}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0zm128-80a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>
                    Set Location
            </button>}
            {loadingSetLoc && <span className='btn btn-small'><span className='loader'></span></span>}
            

            {/* delete favorite location  */}
            {!loading && <button className='btn btn-small' onClick={removeFavoriteHandler}>
                <svg viewBox="-2 0 18 18" xmlns="http://www.w3.org/2000/svg" aria-label="Delete" role="img"><path d="M.996 15.504a1.5 1.5 0 001.501 1.501h9.006a1.5 1.5 0 001.5-1.5V4.996H.997v10.507zM9.502 7.5a.5.5 0 111 0v7.004a.5.5 0 11-1 0V7.5zM6.5 7.5a.5.5 0 011 0v7.004a.5.5 0 11-1 0V7.5zm-3.002 0a.5.5 0 011 0v7.004a.5.5 0 11-1 0V7.5zm10.006-5.503H9.752l-.294-.585a.75.75 0 00-.673-.416H5.211a.742.742 0 00-.669.416l-.294.585H.496a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13.008a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"></path></svg>
                <span>Remove</span>
            </button>}
            {loading && <span className='btn btn-small'><span className='loader'></span></span>}
        </div>
    </Card>
}

export default FavoriteLoc