import classes from './OloLogin.module.css'

const OloLogin = () => {
    return <div className="container-full">
        <div className={classes.olo_login_container}>
            <h1>Olo login</h1>
            <p>Please wait while we process your request...</p>
            <span className='loader dark'></span>
        </div>
    </div>
}


export default OloLogin