import { useEffect } from 'react';
import { getItemFromStorage } from '../../../scripts/localStorage';
import classes from './ProductOptions.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { productDetailsActions } from '../../../store/productDetails';
import ProductContainer from './ProductContainer';
import { useLocation } from 'react-router-dom';

const removeAllSelectedItems = (arr) => {
    arr.forEach(item => {
        if (item.options) {
            item.options.forEach(option => {
                if (!option.itemSelected) {
                    if (option.modifiers) {
                        option.modifiers.forEach(mod => {
                            mod.options.forEach(suboption => {suboption.itemSelected = false})                            
                        })
                        removeAllSelectedItems(option.modifiers)
                    }
                }
            })
        }
    })
}
const selectTheFirstItem = (arr, value, skip ) => {
    arr.forEach(item => item.itemSelected = false)
    

    if (!skip) {
        arr[0] = {...arr[0], itemSelected: value}
    }
    
    if (arr[0].modifiers) {
        arr[0].modifiers.forEach(mod => {
            if (mod.mandatory) {
                selectTheFirstItem(mod.options, true)
            } else {
                selectTheFirstItem(mod.options, true, true)
            }               
        })
    }

    // need to add the option  itemSelected 
    let restOfOptions = arr.slice(1)
    restOfOptions.forEach(r => {
        if (r.modifiers) {
            r.modifiers.forEach(mod => {
                if (mod.mandatory) {
                    selectTheFirstItem(mod.options, false, false)
                } else {
                    selectTheFirstItem(mod.options, false, true)
                }               
            })
        }
    })
}

const selectIsDefaultItem = (arr) => {
    arr.forEach(item => item.itemSelected = false)
    
    arr.forEach(item => {
        if (item.isdefault) {
            item.itemSelected = true
        }
    })

}

const isEditEnabled = (data, choicesToEdit) => {
    data.forEach(item => item.itemSelected = false)

    choicesToEdit.forEach((editChoice => {
        data.forEach(item => {
            if (item.id === editChoice.optionid) {
                item.itemSelected= true
                item.selectedQuantity = editChoice.quantity
            }
            if (item.modifiers) {
                item.modifiers.forEach(mod => {
                    isEditEnabled(mod.options, choicesToEdit)
                })
            }
        })
    }))
}

const ProductOptions = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const product = getItemFromStorage('foundProductMarble')?.product
    const productDetails = useSelector(state => state.productDetails.data)
    const editOptions = useSelector(state => state.productDetails.edit)
    console.log('product::::: ', product)
    useEffect(() => {

        // START GTM DATA
        if  (product) {
            // addDataLayersSingle("view_item", product)
            window.dataLayer = window.dataLayer || [];            
            window.dataLayer.push({
                event: "view_item",
                ecommerce: {
                    currency: "USD", // e.g. "USD",
                    value: product.cost, // e.g. 7.77,
                    test: `this is the view_item event - ${Math.random()}`,
                    items: [{
                        item_id: product.id, 
                        item_name: product.name, 
                        price: product.cost, // e.g. 9.99,
                        quantity: 1, // e.g. 1
                    }]
                }
            });
        }
        // END GTM DATA

        if (product && product.modifiers) {

            if (location.state && location.state.type) {
                console.log('we are in edit product::: ', editOptions)
                let data = JSON.parse(JSON.stringify(product.modifiers))

                console.log('data:::    ', data)
                data.forEach(element => {
                    isEditEnabled(element.options, editOptions.choices)
                });
                dispatch(productDetailsActions.createProduct(data))

                
            } else {
                console.log('this is not the edit product')
                let data = JSON.parse(JSON.stringify(product.modifiers))
                data.forEach(element => {
                    if (element.mandatory || element.description === "Add a soda?") {
                        selectTheFirstItem(element.options, true, false)
                    } else if (!element.mandatory && element.maxselects) {
                        //this is for border color
                        selectIsDefaultItem(element.options)
                    } else {
                        selectTheFirstItem(element.options, false, true)
                    }
                });
                dispatch(productDetailsActions.createProduct(data))
            }
           
        }

    }, [location])

    return <div className={classes.product_container}>
        {product && productDetails && <ProductContainer /> }
    </div>
}

export default ProductOptions