import { Link } from "react-router-dom"
import Card from "../fragments/Card"

import classes from './ProductCard.module.css'

const ProductCard = ({data}) => {

    return <Link to={`../${data.name.toLowerCase().includes('create') ? 'create' : 'product'}/${data.id}`}> 
        <Card key={data.id} >
            <div className={classes.image_container}>
                {data.image_small_url && <img src={data.image_small_url} className='image-cover' alt={data.title}/>}
            </div>
            <div className={classes.text_content}>
                <div className={classes.title}>
                    {data.name}
                </div>
                <div className={classes.cta_container}>
                    <span className={`btn ${classes.btn}`}>Order Now</span>
                </div>
            </div>
        </Card>
    </Link>
}

export default ProductCard