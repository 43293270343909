import classes from './CartItem.module.css'

import { getItemFromStorage } from '../../scripts/localStorage'
import { useEffect, useState } from 'react'

import placeholderImage from '../../assets/MSC_placeholder.jpg'

const CartItemImage = ({data}) => {
    const menu = getItemFromStorage('currentMenuMarble')
    const upsellsItemsFromStorage = getItemFromStorage('cartUpsells')
    const [imageSrc, setImageSrc] = useState(placeholderImage)
    const [hasImage, sethasImage] = useState(false)

    useEffect(() => {
        if (menu) {
            menu.menu.categories.forEach(element => {                
                if (element.products) {                    
                    if (element.products.length > 0) {
                        let foundProductMarble = element.products.find(item => item.id == data.productId)
                        if (foundProductMarble) {
                            setImageSrc(foundProductMarble.image_small_url)
                            sethasImage(true)
                            return
                        } 
                    }
                }

                if (element.sub_categories) {
                    if (element.sub_categories.length > 0) {
                        element.sub_categories.forEach(item => {
                            if (item.products) {                    
                                if (item.products.length > 0) {
                                    let foundProductMarble = item.products.find(prod => prod.id == data.productId)
                                    if (foundProductMarble) {
                                        setImageSrc(foundProductMarble.image_small_url)
                                        sethasImage(true)
                                        return
                                    } 
                                }
                            }
                        })
                    }
                }
            });
        }
        if (upsellsItemsFromStorage) {
            upsellsItemsFromStorage.forEach(group => {
                if (group.items) {
                    let foundProductMarble = group.items.find(item => item.id == data.productId)
                    if (foundProductMarble) {
                        setImageSrc(`${group.imagepath}${foundProductMarble.images[0].filename}`)
                        sethasImage(true)
                        return
                    } 
                }
            })
        }
    }, [])
    return <div className={classes.image_container}>
        <img src={imageSrc} alt={data.title} className={hasImage ? 'image-cover' : 'image-contain'}/>
    </div>
}

export default CartItemImage