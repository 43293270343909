import { Outlet } from "react-router-dom"
import Footer from "./Footer"
import Header from "./Header"
import ScrollToTop from "../../hooks/ScrollToTop"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getItemFromSessionStorage, getItemFromStorage } from "../../scripts/localStorage"
import { locationActions } from "../../store/location"
import { authActions } from "../../store/auth"
import { cartActions } from "../../store/cart"
import ErrorContainer from "./ErrorContainer"
// import { getAuthToken } from "../../scripts/auth"
import { getuserInfo } from "../../scripts/fetchApi"

const Layout = () => {   
    const dispatch = useDispatch()
    const storage = getItemFromStorage('currentLocationMarble')
    useEffect(() => {
        
        if (storage) {
            dispatch(locationActions.setLocationItem(storage.currentLocationMarble))
        }

        const menu = getItemFromStorage('currentMenuMarble')
        if (menu) {
            dispatch(locationActions.setMenuItem(menu.menu))
        }
        // const currentCart = getItemFromStorage('currentCart')
        const currentCart = getItemFromSessionStorage('currentCart')
        if (currentCart) {
            dispatch(cartActions.addCart(currentCart))
        }

        const token = sessionStorage.getItem('token')
        if (token) {
            (async () => {
                const getUser = await getuserInfo(token)
                if (getUser.message) { //there was an error 
                    dispatch(authActions.logout())
                } else {
                    dispatch(authActions.setUser(getUser))
                }
            })()
        }
        const olotoken = sessionStorage.getItem('olotoken')
        if (olotoken) {
            (async () => {
                const getUser = await getuserInfo(olotoken, 'oloauth')

                if (getUser.message) { //there was an error 
                    dispatch(authActions.logout())
                } else {

                    const formatUser = {
                        first_name: getUser.firstname ? getUser.firstname : '',
                        last_name: getUser.lastname ? getUser.lastname : '',
                        name: (getUser.firstname ? getUser.firstname : '') + ' ' + (getUser.lastname ? getUser.lastname : ''),
                        email: getUser.emailaddress,
                        phone: getUser.phone ? getUser.phone : '',
                    }
                    dispatch(authActions.setUser(formatUser))
                }

            })()
        }

        const car = JSON.parse(localStorage.getItem('carInfo'))
        if (car) {
            dispatch(cartActions.setVehicleInfo({
                vehicleMake: car.vehicle_make,
                vehicleModel: car.vehicle_model,
                vehicleColor: car.vehicle_color   
            }))
        }
    }, [dispatch, storage])

    
    return <>
        <div>
            <Header />
            <ScrollToTop />
            <main>
                <Outlet />
            </main>
        </div>
        <ErrorContainer />
        <Footer />
    </>
}

export default Layout