import { useSelector } from "react-redux"
import classes from './Cart.module.css'
import ActiveCart from "./ActiveCart"
import CartUserInfo from "./CartUserInfo"
import CartCheckout from "./CartCheckout"
import OrderStep from "./steps/OrderStep"
import OrderInfo from "./steps/OrderInfo"
import OrderCheckout from "./steps/OrderCheckout"
// import DeliveryIcon from "../../icons/deliveryicon"
// import StoreIcon from "../../icons/storeicon"
// import CurbsideIcon from "../../icons/curbsideicon"

import EmptyCart from "./EmptyCart"
import CartHeader from "./CartHeader"

const Cart = () => {

    const cart = useSelector(state => state.cart.cart)    

    const cartItems = cart !==null ? cart.products : null

    const numberOfProductsInCart = cart !== null ? cartItems?.length : 0

    const totalPrice = cart !== null ? cart?.total : 0

    const sidebarStep = useSelector(state => state.cart.cartSidebarStep)

    const locationStore = useSelector(state => state.location.location)   
    
    if (cart) {
        console.log('CART::::: ', cart)
    }

    return <> 
        <div className={classes.sidebar_header}>
            <CartHeader cart={cart} location={locationStore} />
            {/* {cart && <>
                <div className={classes.sidebar_header_message}>
                    {cart.deliverymode === 'dispatch' && <DeliveryIcon />}
                    {cart.deliverymode === 'pickup' && <StoreIcon />}
                    {cart.deliverymode === 'curbside' && <CurbsideIcon fill="#584646" dimensions={{width: 20}}/>}

                    <span className={classes.sidebar_header_message_type}>{cart.deliverymode === 'dispatch' ? 'Delivery' : cart.deliverymode}</span>
                    {cart.deliverymode !== 'dispatch' && <span className={classes.sidebar_header_message_store_name}>{locationStore.address.street}, {locationStore.address.zip}, {locationStore.address.city}</span>}
                    {cart.deliverymode === 'dispatch' && <span className={classes.sidebar_header_message_store_name}>{cart.deliveryaddress.streetaddress} {cart.deliveryaddress.zipcode} {cart.deliveryaddress.city}</span>}
                    
                </div>
            </>}    

            {!cart && <>
                <div className={classes.sidebar_header_message}>
                    {locationStore && <>
                        <StoreIcon />
                        <span className={classes.sidebar_header_message_type}>Pickup</span>
                        <span className={classes.sidebar_header_message_store_name}>{locationStore.address.street}, {locationStore.address.zip}, {locationStore.address.city}</span>
                    </>}
                    {!locationStore && <>Pick a location to start your order!</>}
                </div>
            </>}        */}
        </div>
        
        
        <div className={classes.sidebar_content}>
            <div className={`${classes.sidebar_content_inner} ${(cart === null || cart.products.length === 0) ? classes.sidebar_content_inner_empty : ''}`}>
                {numberOfProductsInCart > 0 && <>
                    <div className={classes.cart_timeline}>
                        <OrderStep />
                        <OrderInfo />
                        <OrderCheckout />
                    </div>   

                    {sidebarStep === "order" && <div className={classes.cart_items}>
                        <ActiveCart items={cartItems} cartId={cart.id} totalPrice={totalPrice} />                        
                    </div>}

                    {sidebarStep === "info" && <div className={classes.cart_items}>
                        <CartUserInfo />        
                    </div>}

                    {sidebarStep === "checkout" && <CartCheckout cartId={cart.id} totalPrice={totalPrice} />}  
 
                </>}
                {/* {numberOfProductsInCart === 0 && <>
                    <MockSteps />
                </>} */}
                {(cart === null || cart.products.length === 0) && <EmptyCart />}

                {/* {(cart === null || cart.products.length === 0) && <div className={classes.no_items}>
                    <div className="sidebar-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
                    </div>
                    <h3 className="sidebar-title">No items in cart</h3>
                </div>}               */}
            </div>        
        </div>        
    </>
}

export default Cart