import { useDispatch, useSelector } from "react-redux"

import classes from './EmptyCart.module.css'
import { useNavigate } from "react-router-dom"
import { locationActions } from "../../store/location"
import { cartActions } from "../../store/cart"

import meltedIce from '../../assets/meltedicecream.svg'

const EmptyCart = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let locationStore = useSelector(state => state.location.location)

    const openLocationSidebar = () => {
        dispatch(cartActions.setCartSidebarStep("order"))
        dispatch(cartActions.setCartSidebarState())    
        dispatch(locationActions.setLocationsSideBarState())        
    }

    const navigateTohandler = () => {
        console.log('locationStore::: ', locationStore)
        dispatch(cartActions.setCartSidebarState()) 
        navigate(`${locationStore.slug}/menu/cookie-cakes`, { state: { toHolidays: 'Holidays' } })
    }

    


    return <div className={classes.no_items_container}>
        <div className={classes.no_items_container_inner}>
            <img src={meltedIce} alt="bird eating crumbs"/>
            <h3 className={classes.title}>Your bag is empty!</h3>
            <p className={classes.description}>It’s lonely in here.</p>
            <div className={classes.no_items_container_footer}>
                {locationStore && <button to="cookie-cakes" className='btn-fancy' onClick={navigateTohandler}>Order Now</button>}
                {/* {!noLocation && <Link to="cookie-cakes" className='btn-fancy'>Order Now</Link>} */}
                {!locationStore && <button to="cookie-cakes" className='btn-fancy' onClick={openLocationSidebar}>Order Now</button>}
            </div>
        </div>
    </div>
}

export default EmptyCart