import { useEffect } from 'react'
import Signin from '../authentication/Signin'
import classes from './AccountLanding.module.css'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getGenericToken } from '../../scripts/helpers'

const SignIn = () => {
    const navigate = useNavigate()
    // const token = localStorage.getItem('token')
    // const token = sessionStorage.getItem('token')

    const token = getGenericToken()

    const user = useSelector(state => state.auth.user)
    useEffect(() => {
        navigate('/')
        if (token) {
            navigate('/account')
        }
        if (user) {
            navigate('/account')
        }

    }, [token, navigate, user])

    const navigateToAccountHandler = () => {
        navigate('/account')
    }


    return <div className="container-full">
        <div className={classes.wrapper}>
            <h1 className='text-center'>Sign in to access your account page</h1>
            <div className={classes.form_wrapper}>
                <Signin closeSidebar={navigateToAccountHandler}/>
            </div>
        </div>
    </div>
}

export default SignIn