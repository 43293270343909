const DateSelect = ({handleDateChange}) => {
    let options = []
    for (let index = 0; index < 7; index++) {
        let date = new Date();
        date.setDate(date.getDate() + index);
        options.push({
            value: date.toISOString().split('T')[0],
            label: (new Date(date)).toLocaleString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric'
            })
        })        
    }

    return <select className='select' onChange={handleDateChange} defaultValue={'Date'}>
        <option disabled value="Date" hidden>Date</option>
        {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
        ))}
    </select>
}

export default DateSelect