import ReactDOM from "react-dom";

import Bannerv2 from "./Bannerv2";
import MockProductPage from "./MockProductPage";
import MockSuperCategories from "./MockSuperCategories";
import BannerSimple from "./BannerSimple";

const LoadingWrapper = ({text, productPage}) => {
    return <>
    {!productPage && <>
        {/* <Bannerv2 /> */}
        <BannerSimple />
        <MockSuperCategories />
    </>}
    {productPage && <MockProductPage />}
    {ReactDOM.createPortal(
        <div className='preparing_restaurant'>
            <div className='preparing_restaurant_backdrop'>
                <div className='preparing_restaurant_container'>
                    <div className='preparing_restaurant_loader'></div>
                    <div className='preparing_restaurant_message'>{text}</div>
                </div>
            </div>
        </div>,
        document.getElementById("overlay-root")
    )}
    </>
}

export default LoadingWrapper