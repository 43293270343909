import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from '../../store/location';
import { variousActions } from '../../store/various';
import { cartSetDeliveryMethod, createCart, getMenuFromLocationId, setVehicle } from '../../scripts/fetchApi';
import ReactDOM from "react-dom";
import { useNavigate  } from "react-router-dom";
import { setStorageItem, deleteItemFromStorage, getItemFromStorage, deleteItemFromSessionnStorage, setSessionStorageItem, getItemFromSessionStorage } from '../../scripts/localStorage';



import classes from './Location.module.css'
import { useEffect, useState } from 'react';
import { cartActions } from '../../store/cart';
import { virtualCartActions } from '../../store/virtualCart';

// import marbleLogo from '../../assets/marble-slab-v2.png'
import marbleLogov2 from '../../assets/GAC_MSC_logo.svg'
import StoreIcon from '../../icons/storeicon';
import ArrowUpIcon from '../../icons/arrowupicon';
import PhoneIcon from '../../icons/phoneicon';
import ClockIcon from '../../icons/clockicon';
import Popup from '../common/Popup';
import { formatOLODate, formatOLOTime, getDayOfTheWeek, splitAndReturnNormalDate, splitAndReturnNormalTime } from '../../scripts/helpers';



const Location = ({data, deliveryType, itemIndex}) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [storeClosed, setStoreClosed] = useState(false)

    const dispatch = useDispatch();
    let vehicleInfo = useSelector(state => state.cart.vehicleInfo)

    

    let cart = useSelector(state => state.cart.cart)

    let time = useSelector(state => state.location.order)
    
    const fetchData = async (cartMethod) => {
        const menu = await getMenuFromLocationId(data.id)
        //first delete cart
        // deleteItemFromStorage('currentCart')
        deleteItemFromSessionnStorage('currentCart')
        dispatch(cartActions.addCart(null))
        dispatch(virtualCartActions.clearVirtualCart())
        dispatch(locationActions.setLocationItem(data))
        dispatch(locationActions.setMenuItem(menu))
        dispatch(locationActions.setLocationsSideBarState())
        dispatch(locationActions.setVirtualDeliveryType(deliveryType))
        setStorageItem('currentLocationIdMarble', {
            currentLocationIdMarble: data.id,
            currentLocationSlug: data.slug
        }, null) 
        setStorageItem('currentLocationMarble', {
            currentLocationMarble: data,
            deliveryType: deliveryType,
            time: time
        }, 3600)  //, 7200
        setStorageItem('currentMenuMarble', {
            menu: menu
        }, 3600) //, 7200

        dispatch(cartActions.addCart(cartMethod))
        // setStorageItem('currentCart', cartMethod)
        setSessionStorageItem('currentCart', cartMethod)

        // clear any time that might be saved 
        dispatch(locationActions.setOrderDate(null))
        dispatch(locationActions.setOrderTime(null))
        
        setLoading(false)
        navigate(`/${menu.slug}/menu`);
                
    } 


    if (cart !== null) {
        if (cart.id === undefined) {
            // dispatch(cartActions.addCart(getItemFromStorage('currentCart')))
            dispatch(cartActions.addCart(getItemFromSessionStorage('currentCart')))
        }
    }

    const setLocation = async () => {
        // new method to set location with cart.
        setLoading(true);

        if (deliveryType === 'curbside') {
            localStorage.setItem('carInfo', JSON.stringify(vehicleInfo))
        }

        const newCart = await createCart({restaurant_id: data.id})


        if (newCart.id) {
            // const deliveryCartInfo = {
            //     delivery_mode: deliveryType,
            //     time: time.type,
            //     schedule_date: time.date,
            //     schedule_time: time.time
            // }  
            const deliveryCartInfo = {
                delivery_mode: 'pickup',
                time: 'asap',
                schedule_date: null,
                schedule_time: null
            } 
            
            if (!data.status.open) { //location is closed -set scheduled
                deliveryCartInfo.time = 'schedule'
                deliveryCartInfo.schedule_date = splitAndReturnNormalDate(data.hours.business[getDayOfTheWeek()]?.start)
                deliveryCartInfo.schedule_time = splitAndReturnNormalTime(data.hours.business[getDayOfTheWeek()]?.start)
            }

            // console.log(deliveryCartInfo)
            // set delivery method
            const cartMethod = await cartSetDeliveryMethod(newCart.id, deliveryCartInfo)
            if (cartMethod.id) {
                //set vehicle info if curbside  
                // if (deliveryType === 'curbside') {  //curbside
                //     if (cartMethod.id) {
                //         const vehicle = {
                //             vehicle_make: vehicleInfo.vehicleMake,
                //             vehicle_model: vehicleInfo.vehicleModel,
                //             vehicle_color: vehicleInfo.vehicleColor,
                //         }
                //         const cartVehicle = await setVehicle(cartMethod.id, data.id, vehicle)

                //         if (cartVehicle.id) {  //success
                //             fetchData(cartVehicle)
                //         } else {
                //             dispatch(variousActions.setError(cartVehicle))
                //         }
                        
                //     } else { 
                //         dispatch(variousActions.setError(newCart))
                //     }
                // } else { // pickup
                    fetchData(cartMethod)
                // }
            } else {
                dispatch(variousActions.setError(cartMethod))
                setLoading(false);
            }
            

            
            
        } else {
            dispatch(variousActions.setError(newCart))
            setLoading(false);
        }   
    }

    const pickLocationHandler = async () => {
        // console.log('picking location::: ', data)

        if (!data.status.open) { //store is closed, show modal
            setStoreClosed(true)
        } else {
            setLocation()
        }
           
 
    }      

    const [locDetails, setLocDetails] = useState(false)
    const openLocationDetailsHandler = () => {
        setLocDetails(prevState => prevState = !prevState)
    }
    useEffect(() => {
        if (itemIndex === 0) {
            setLocDetails(true)
        }
    }, [])


    const closeModalHandler = () => {
        setStoreClosed(false)
    }


    const continueAndSetLocation = async () => {
        setStoreClosed(false)
        setLocation()    
    }

    return <>
        <div className={`${classes.location} ${locDetails && classes.location_active}`} >
            <div className={classes.location_inner}>
                <div className={classes.location_header}>
                    <div className={classes.status}>
                        {data.status.open && <>
                            <div className={classes.icon}>
                                <StoreIcon />              
                            </div>
                            <span className={classes.status_text}>Store Open</span>
                        </>}
                        {!data.status.open  && <>
                            <div className={classes.icon}>
                                <StoreIcon />         
                            </div>
                            <span className={classes.status_text}>Store closed</span>
                        </>}
                    </div>
                    <div className={classes.location_header_controls}>              
                        <button className='btn btn-small' onClick={pickLocationHandler} title='select this location'>Select Location</button>
                    </div>
                </div>

                {data.name && <div className={classes.title}>
                    {/* {data.name} */}
                    {data.address.street}<br />
                    {data.address.city}, {data.address.state}, {data.address.zip}
                    <button onClick={openLocationDetailsHandler} className={classes.loc_details_trigger}>
                        <ArrowUpIcon />
                        <span className='sr-only'>open location</span>
                    </button>
                </div>}
                
                {data.distanceFromSearch && <div className={classes.distance}>({data.distanceFromSearch} miles away)</div>}        

                {locDetails && data.telephone && <div className={classes.phone}>
                    <PhoneIcon />
                    <div className={classes.program_text}>{data.telephone}</div>
                </div>}
                

                <div className={classes.program}>
                    <ClockIcon />
                    <div className={classes.program_text}>
                        {new Date(data.hours.business[getDayOfTheWeek()]?.start).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" })} 
                        <span> - </span> 
                        {new Date(data.hours.business[getDayOfTheWeek()]?.end).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" })}
                    </div>
                </div>
            </div>
            {data.cobranded && <div className={classes.cobranded_container}>
                <div className={classes.cobranded_image}>
                    {marbleLogov2 && <img src={marbleLogov2} alt='cobranded'/>}                    
                </div>
                <div className={classes.cobranded_text}>
                    <span>Co-Branded Store</span>
                    This store also sells products from <a href='https://www.marbleslab.com/' rel="noreferrer" target='_blank'>Marble Slab Creamery</a>.
                </div>
            </div>}
            {/* {locDetails && data.cobranded && <div className={classes.cobranded_container}>
                <div className={classes.cobranded_image}>
                    {marbleLogov2 && <img src={marbleLogov2} alt='cobranded'/>}                    
                </div>
                <div className={classes.cobranded_text}>
                    <span>Co-Branded Store</span>
                    This store also sells products from <a href='https://www.marbleslab.com/' rel="noreferrer" target='_blank'>Marble Slab Creamery</a>.
                </div>
            </div>} */}

            {loading && <>            
                {ReactDOM.createPortal(
                    <div className='preparing_restaurant'>
                        <div className='preparing_restaurant_backdrop'>
                            <div className='preparing_restaurant_container'>
                                <div className='preparing_restaurant_loader'></div>
                                {/* <div className='preparing_restaurant_message'>Preparing your personal restaurant! Please wait</div> */}
                            </div>
                        </div>                   
                    </div>,
                    document.getElementById("overlay-root")
                )}
            </>}
        </div>
        {storeClosed && <Popup transition={storeClosed}>
            <p className={classes.warning_header}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                <strong>Warning:</strong> {data.name} is not opened yet!
            </p>
            <p>The store will open today at {new Date(data.hours.business[getDayOfTheWeek()]?.start).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" })}</p>
            <div className={classes.modal_controller}>
                <button className='btn btn-border' onClick={closeModalHandler}>Change Location</button>
                <button className='btn' onClick={continueAndSetLocation}>Proceed Anyway</button>
            </div>
        </Popup>}
    </>
}

export default Location