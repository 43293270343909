import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux'
import classes from './Header.module.css'
import { locationActions } from '../../store/location'
import StoreIcon from '../../icons/storeicon'
import DeliveryIcon from '../../icons/deliveryicon'
import CurbsideIcon from '../../icons/curbsideicon'
import { useRef } from 'react'
import ChangeSelections from './ChangeSelections'
import { CSSTransition } from 'react-transition-group';
import { formatAndSplitDates, getDayOfTheWeek } from '../../scripts/helpers'
import EarliestReadyDate from "./EarliestReadyDate";
import TimeWanted from "./TimeWanted";


const HeaderLocationDesktop = () => {
    const modalOpened = useSelector(state => state.location.changeDeliveryModal)
    const nodeRef = useRef(null)
    const dispatch = useDispatch()

    const locationStore = useSelector(state => state.location.location)
    const cart = useSelector(state => state.cart.cart)


    function calcTime(offset) {
        var d = new Date();
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        var nd = new Date(utc + (3600000*offset));
        return (new Date(locationStore.hours.business[getDayOfTheWeek()].start)) < nd
    }

    const changeSelectionHandler = () => {
        dispatch(locationActions.setChangeDelivery({
            close: false,
            value: true
        }))
    }

    const closeChangeSelectorHandler = () => {
        dispatch(locationActions.setChangeDelivery({
            close: true,
            value: false
        }))
    }    

    return <div className={`${classes.control_box} ${classes.control_box_location_container}`}>
        <div className={classes.control_box_icon}>
            {cart && <>
                {cart.deliverymode === 'pickup' && <StoreIcon />}
                {cart.deliverymode === 'curbside' && <CurbsideIcon />}
                {cart.deliverymode === 'dispatch' && <DeliveryIcon />}
            </>}
            {!cart && <StoreIcon />}
        </div>
        <div className={classes.text_block}>
            <button onClick={changeSelectionHandler} title='Change Selection'>
                {cart && <>
                    {/* there is a pickup cart and order time is ASAP  */}
                    {cart.deliverymode === 'pickup' && cart.timemode !== "advance" && <span>
                        {locationStore && <>
                            {locationStore.status.open && <>{cart.deliverymode + ' asap from'}</>}
                            {!locationStore.status.open && <>{cart.deliverymode + ' open at '}{new Date(locationStore.hours.business[getDayOfTheWeek()].start).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" })}</>}
                            {/* {!locationStore.status.open && <><EarliestReadyDate method="Pickup" /></>} */}
                        </>}
                                                
                    </span>}
                    {/* there is a pickup cart and order time is scheduled  */}
                    {cart.deliverymode === 'pickup' && cart.timemode === "advance" && <span>
                        {locationStore && <>
                            {/* <TimeWanted /> */}
                            {cart.deliverymode + ' on '} 
                            {cart.timewanted && <>{formatAndSplitDates(cart.timewanted, {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: 'numeric', 
                                hour12: true,
                                minute: "2-digit"
                            })}</>}
                            {!cart.timewanted && <>{formatAndSplitDates(cart.earliestreadytime, {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: 'numeric', 
                                hour12: true,
                                minute: "2-digit"
                            })}</>}
                        </>}
                                                
                    </span>}

                    {/* there is a curbside cart and order time is asap  */}
                    {cart.deliverymode === 'curbside' && cart.timemode !== "advance" && <span>
                        {/* {cart.deliverymode + ' asap from'} */}
                        {locationStore && <>
                            {locationStore.status.open && <>Curbside asap from</>}
                            {!locationStore.status.open && <>{cart.deliverymode + ' open at '}{new Date(locationStore.hours.business[getDayOfTheWeek()].start).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" })}</>}

                        </>}
                    </span>}
                    {/* there is a curbside cart and order time is scheduled  */}
                    {cart.deliverymode === 'curbside' && cart.timemode === "advance" && <span>
                        {locationStore && <>
                            {cart.deliverymode + ' on '}  
                            {cart.timewanted && <>{formatAndSplitDates(cart.timewanted, {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: 'numeric', 
                                hour12: true,
                                minute: "2-digit"
                            })}</>}
                            {!cart.timewanted && <>{formatAndSplitDates(cart.earliestreadytime, {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: 'numeric', 
                                hour12: true,
                                minute: "2-digit"
                            })}</>}
                        </>}
                                                
                    </span>}

                    {/* there is a dispatch cart and order time is asap  */}
                    {cart.deliverymode === 'dispatch' && cart.timemode !== "advance" && <span>Delivery asap to</span>}
                    {/* there is a dispatch cart and order time is scheduled  */}
                    {cart.deliverymode === 'dispatch' && cart.timemode === "advance" && <span>
                        {locationStore && <>
                            {'Delivery on '} 
                            {cart.timewanted && <>{formatAndSplitDates(cart.timewanted, {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: 'numeric', 
                                hour12: true,
                                minute: "2-digit"
                            })}</>}
                            {!cart.timewanted && <>{formatAndSplitDates(cart.earliestreadytime, {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: 'numeric', 
                                hour12: true,
                                minute: "2-digit"
                            })}</>}
                        </>}
                                                
                    </span>}
                </>}
                {!cart && <span>
                    {/* Pickup from?? */}
                    {locationStore && <>
                        {calcTime('-5') && <>Pickup from</>}
                        {!calcTime('-5') && <>Pickup open at {new Date(locationStore.hours.business[getDayOfTheWeek()].start).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" })}</>}
                    </>}
                </span>}
                {cart && cart.deliverymode !== 'dispatch' && <div className={classes.text_block_address}>{locationStore.address && locationStore.address.street }</div>}
                {cart && cart.deliverymode === 'dispatch' && <div className={classes.text_block_address}>{cart.deliveryaddress.streetaddress}, {cart.deliveryaddress.city}, {cart.deliveryaddress.zipcode}</div>}
                {!cart && <div className={classes.text_block_address}>{locationStore.address && locationStore.address.street }</div>}
                <span className={`btn-underline ${classes.btn_underline}`}>Change</span>
            </button>

        </div>
        <CSSTransition 
            in={modalOpened} 
            nodeRef={nodeRef} 
            timeout={400}
            classNames="top-drop"
            unmountOnExit>
            <div ref={nodeRef} className={classes.change_selections_container_outer}>
                <ChangeSelections closeChangeSelector={closeChangeSelectorHandler}/>
                {ReactDOM.createPortal(
                    <div className="background-invisible" onClick={closeChangeSelectorHandler}></div>,
                    document.getElementById("backdrop-root")
                )}
            </div>
        </CSSTransition>
        {/* {modalOpened && <ChangeSelections />} */}
    </div>
}


export default HeaderLocationDesktop