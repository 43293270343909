import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"
import { cartActions } from "../../store/cart";
import { productDetailsActions } from "../../store/productDetails";
import { getItemFromStorage } from "../../scripts/localStorage";
import { useState, useEffect } from "react";

const CartItemEdit = ({itemData}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useSelector(state => state.location.location)  
    const menu = getItemFromStorage('currentMenuMarble')
    const [productNotUpsells, setProductNotUpsells] = useState(false)

    useEffect(() => {
        if (menu) {
            menu.menu.categories.forEach(element => {                
                if (element.products) {                    
                    if (element.products.length > 0) {
                        let foundProductMarble = element.products.find(item => item.id == itemData.productId)
                        if (foundProductMarble) {
                            setProductNotUpsells(true)
                            return
                        } 
                    }
                }

                if (element.sub_categories) {
                    if (element.sub_categories.length > 0) {
                        element.sub_categories.forEach(item => {
                            if (item.products) {                    
                                if (item.products.length > 0) {
                                    let foundProductMarble = item.products.find(prod => prod.id == itemData.productId)
                                    if (foundProductMarble) {
                                        setProductNotUpsells(true)
                                        return
                                    } 
                                }
                            }
                        })
                    }
                }
            });
        }
    }, [])
    
    const editHandler = () => {
        if (itemData.name.toLowerCase().includes('create')) {
            navigate(`/${location.slug}/menu/create/${itemData.productId}`,{state:{type: 'edit', productInCart: itemData.id}});
        } else {
            navigate(`/${location.slug}/menu/product/${itemData.productId}`,{state:{type: 'edit', productInCart: itemData.id}});
        }
        
        dispatch(cartActions.setCartSidebarState())
        dispatch(productDetailsActions.setEditOptions(itemData))
    }


    return <div>
        {productNotUpsells && <button className='btn-underline' onClick={editHandler}>Edit</button>}
    </div>
}

export default CartItemEdit