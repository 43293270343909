import classes from "./NonOloPay.module.css";
import { useRef, useState } from "react"
import DateInputMaks from "./DateInputMaks"
import { cartActions } from "../../store/cart";
import { virtualCartActions } from "../../store/virtualCart";
import { deleteItemFromSessionnStorage, getItemFromStorage } from "../../scripts/localStorage";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../scripts/auth";
import { variousActions } from "../../store/various";
import { addDataLayerCheckout, deleteCookie, getCookie, getGenericToken, isEmpty, isLength5 } from "../../scripts/helpers";
import { cartCCSFtoken, validateCart } from "../../scripts/fetchApi";
import { useDispatch, useSelector } from "react-redux";

const NonOloPayCheckout = ({checkoutFrame}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const dateInputRef = useRef()
    const zipInputRef = useRef()
    const [loading, setLoading]  = useState(false)
    let cart = useSelector((state) => state.cart.cart);
    const guest = getItemFromStorage('guestUser')
    const user = useSelector(state => state.auth.user)

    const hasUserOptedIn = useSelector(state => state.cart.hasUserOptedIn)
    let hasLocation = useSelector(state => state.location.location)

    const [zipValue, setZipValue] = useState("")  

    const zipChangehandler = (e) => {
        const regex = /^[0-9\b]+$/;
        if ((e.target.value === "" || regex.test(e.target.value)) && e.target.value.length < 6) {
            setZipValue(e.target.value)          
        }
    }

    const submitBasketHandler = async () => {
        setLoading(true)
        const hasErrors = {
            phone: true,
            zip: true
        }
        let enteredMonth = null
        let enteredYear = null

        const rwg_token = getCookie("rwg_token")

        // date validate        
        const entereddate = dateInputRef.current.value
        if (!isEmpty(entereddate)) {
            let month = entereddate.split('/')[0]
            let year = entereddate.split('/')[1]
            

            if (/^\d+$/.test(month) && /^\d+$/.test(year)) {
                
                var now = new Date();
                const selectedDate = new Date(`${year}-${month}-01`);
                if (selectedDate > now) {
                    enteredYear = year
                    enteredMonth = month                
                    hasErrors.phone = false
                } else {
                    hasErrors.phone = true
                    dispatch(variousActions.setError({message: "Please check date format"}))
                }
            } else {
                hasErrors.phone = true
                dispatch(variousActions.setError({message: 'Please provide a valid date'}))
            }

            
        } else {
            hasErrors.zip = true
            dispatch(variousActions.setError({message: 'Please provide a valid date'}))
        }
        //zip validate
        const enteredZip = zipInputRef.current.value
        if (!isEmpty(enteredZip) && isLength5(enteredZip)) {
            hasErrors.zip = false
        } else {
            hasErrors.zip = true
            dispatch(variousActions.setError({message: 'Please provide a valid zip code'}))
        }


        

        if (!hasErrors.phone && !hasErrors.zip) {
            const userToken = getGenericToken()
            const getToken = async () => {
                const token = await cartCCSFtoken(cart.id, null)
                return token
            }
    
            const token = await getToken()
    
            
            if (token && token.accesstoken) {
    
                const validCart = await validateCart(cart.id)

                if (validCart.id) {
                    if (user) {

                        const checkoutOptions = {
                            id: validCart.id,
                            accessToken: token.accesstoken,
                            userType: "user",
                            authtoken: userToken,
                            firstName: user.first_name,
                            lastName: user.last_name,
                            emailAddress: user.email,
                            contactNumber: user.phone.replace(/\D/g,''),
                            billingAccounts: [{
                                amount: cart.total,
                                billingMethod: "creditcard",
                                city: "",
                                expiryMonth: enteredMonth,
                                expiryYear: enteredYear,
                                state: "",
                                streetAddress: "",
                                streetAddress2: "",
                                zip: enteredZip,
                                tipPortion: cart.tip
                            }]
                        }

                        // if (rwg_token) {
                        //     checkoutOptions.rwg_token = rwg_token
                        // }
                        console.log('user submit checkoutOptions::: ', checkoutOptions)
                        
                        checkoutFrame.submit(checkoutOptions);
                    } else {
                        if (guest) {
                            const checkoutOptions = {
                                id: cart.id,
                                accessToken: token.accesstoken,
                                firstName: guest.firstName,
                                lastName: guest.lastName,
                                emailAddress: guest.email,
                                contactNumber: guest.phone.replace(/\D/g,''),
                                userType: "guest",
                                createoloaccount: hasUserOptedIn,
                                billingAccounts: [{
                                    amount: cart.total,
                                    billingMethod: "creditcard",
                                    city: "",
                                    expiryMonth: enteredMonth,
                                    expiryYear: enteredYear,
                                    state: "",
                                    streetAddress: "",
                                    streetAddress2: "",
                                    zip: enteredZip,
                                    tipPortion: cart.tip
                                }]
                            }
                            // if (rwg_token) {
                            //     checkoutOptions.rwg_token = rwg_token
                            // }
                            console.log('guest::: ', checkoutOptions)
                            checkoutFrame.submit(checkoutOptions);
                        }
                    }
                } else {
                    dispatch(variousActions.setError(validCart))
                }

            }
            
    
            checkoutFrame.on("success", function (order) {
                // full order field list below

                if (rwg_token) {
                    deleteCookie("rwg_token")
                }

                deleteItemFromSessionnStorage('currentCart')
                dispatch(cartActions.addCart(null))
                dispatch(cartActions.setCartSidebarState())
                dispatch(cartActions.setBilling(null))
                dispatch(virtualCartActions.clearVirtualCart())
                addDataLayerCheckout(order, hasLocation)
                navigate(`/order/${order.id}`) 
            });
            checkoutFrame.on("error", function (errors) {
                errors.forEach(error => {
                    dispatch(variousActions.setError({message: error.description}))
                });
            });
        }

        setLoading(false)
    }

    return <>
        <div className="form-controller-group">
            <div className="form-controller">
                <label className="label" htmlFor="dateInput">
                    Expiry Date
                </label>
                {/* <input ref={dateInputRef} value={val} type='text' id='dateInput' name='dateInput' placeholder='01/1970' required onChange={formatDateInput}/> */}                       
                <DateInputMaks refVar={dateInputRef}/>
            </div>
            <div className="form-controller">
                <label className="label" htmlFor="locationZip">
                    Zip Code
                </label>
                <input ref={zipInputRef} value={zipValue} type='text' id='locationZip' name='locationZip' placeholder='12345' required onChange={zipChangehandler}/>
            </div>
        </div>
        <div className={classes.submit_container}>
            {!loading && <button onClick={submitBasketHandler} className={`btn ${classes.set_credit_card}`}>Place order | {cart.total.toFixed(2)}</button>}
            {loading && <div className={`btn ${classes.set_credit_card}`}><span className="loader"></span></div>}
        </div>
    </>
}

export default NonOloPayCheckout