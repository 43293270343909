import classes from './ModifierTitle.module.css'
import OptionsHeaderText from '../products_details_v2/OptionsHeaderText'
import ArrowUpIcon from '../../../icons/arrowupicon'
import { useEffect, useRef } from 'react'
import Badge from './Badge'

const ModifierTitle = ({modifier, setDrawer}) => {
    const optionRef = useRef(null)
    
    console.log('modifier::::: ', modifier)
    const openDrawerHandler = () => {
        setDrawer()        
    }

    useEffect(() => {
        // let titles = document.querySelectorAll('.option-header')
        // console.log(titles)
        if (modifier.drawerOpened) {
            optionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [modifier])



    return <button className={`option-header ${classes.options_item_header}`} onClick={openDrawerHandler} ref={optionRef}>
         {/* ${(itemIndex === index) && classes.trigger_open} */}
        <div className={classes.options_item_header_text}>
            {modifier.itemSelected && <Badge />}  
                
            {/* id={data.id} */}
            <h3>
                <span className={`header-index ${classes.item_number}`}>1</span> 
                <OptionsHeaderText text={modifier.description}/>
                {/* no aggregate  */}
                {/* {modifier.maxselects && !modifier.maxaggregatequantity && <span className={classes.options_item_header_helper}> Select up to {modifier.maxselects}</span>} */}
                {modifier.maxselects && !modifier.maxaggregatequantity && modifier.minselects && <span className={classes.options_item_header_helper}> Select betwen {modifier.minselects} and {modifier.maxselects}</span>}
                {modifier.maxselects && !modifier.maxaggregatequantity && !modifier.minselects && <span className={classes.options_item_header_helper}> Select up to {modifier.maxselects}</span>}


                {/* aggregate  */}
                {modifier.maxselects && modifier.maxaggregatequantity && <span className={classes.options_item_header_helper}> Select a total of {modifier.maxselects}</span>} 
            </h3>
            
        </div>
        <span className={classes.options_item_header_arrow}>
            <ArrowUpIcon />
        </span>
    </button>
}

export default ModifierTitle