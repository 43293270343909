import classes from './Footer.module.css'

const Footer = () => {
    return <footer className={classes.footer}>
        <div className={classes.footer_top}>
            <div className='container-full'>
                <nav className={classes.footer_main_menu}>
                    <ul>
                        <li>
                            <a href='https://www.greatamericancookies.com/our-story/' target='_blank' rel='noreferrer'>About Us</a>
                        </li>
                        <li>
                            <a href='https://www.greatamericancookies.com/contact-us/' target='_blank' rel='noreferrer'>Contact Us</a>
                        </li>
                        <li>
                            <a href='https://www.greatamericancookies.com/rewards/' target='_blank' rel='noreferrer'>Rewards</a>
                        </li>
                        <li>
                            <a href='https://www.greatamericancookies.com/locations/' target='_blank' rel='noreferrer'>Locations</a>
                        </li>
                        {/* <li>
                            <Link to='/faqs'>Faqs</Link>
                        </li> */}
                        <li>
                            <a href='https://www.greatamericancookies.com/wp-content/uploads/sites/2/2023/01/GAC-Nutrition-FINAL-6.23.21.pdf' target='_blank' rel='noreferrer'>Nutritional information</a>
                        </li>
                    </ul>
                </nav>
                <div className={classes.copyright}>
                    © 2023 Great American Cookies & Marble Slab Creamery. We’re part of the FAT Brands Family.
                </div>

                <nav className={classes.footer_legal_menu}>
                    <ul>
                        <li>
                            <a href='https://www.greatamericancookies.com/contact-us/' target='_blank' rel='noreferrer'>Contact Us</a>
                        </li>
                        <li>
                            <a href="https://www.greatamericancookies.com/privacy-policy/" target='_blank' rel='noreferrer'>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://www.greatamericancookies.com/accessibility-statement/" target='_blank' rel='noreferrer'>Accessibility Statement</a>
                        </li>
                    </ul>
                </nav>

                <nav className={classes.socials_menu}>
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/greatamericancookies" target='_blank' rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="60" viewBox="0 0 62 60" fill="none">                                    
                                    <path d="M16.135 57.2219C6.28204 53.0389 0.567018 46.2597 1.02402 36.7697C1.42358 29.5679 2.95154 22.4736 5.55203 15.7458C10.794 2.11078 18.317 -1.30056 36.052 0.405444C55.644 2.28344 62.4241 9.26683 60.7621 25.8058C60.2371 37.9708 56.072 48.3869 43.903 55.5739C39.346 58.4023 34.1031 59.9324 28.74 59.9997C24.3933 59.939 20.1045 58.994 16.135 57.2219Z" fill="white"/>
                                </svg>
                                <svg className={classes.social_icon} xmlns="http://www.w3.org/2000/svg" width="15" height="26" viewBox="0 0 15 26" fill="none">
                                    <path d="M15.001 4.31598H12.28C10.148 4.31598 9.73602 5.228 9.73602 6.565V9.51599H14.82L14.159 14.139H9.73702V26H4.43702V14.141H-0.000976562V9.51801H4.43502V6.10999C4.43502 2.15299 7.11803 0 11.035 0C12.912 0 14.526 0.125007 14.998 0.182007L15.001 4.31598Z" fill="#0b818e"/>
                                </svg>
                                <span className='sr-only'>facebook</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/gr8amcookies" target='_blank' rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="60" viewBox="0 0 62 60" fill="none">                                    
                                    <path d="M16.135 57.2219C6.28204 53.0389 0.567018 46.2597 1.02402 36.7697C1.42358 29.5679 2.95154 22.4736 5.55203 15.7458C10.794 2.11078 18.317 -1.30056 36.052 0.405444C55.644 2.28344 62.4241 9.26683 60.7621 25.8058C60.2371 37.9708 56.072 48.3869 43.903 55.5739C39.346 58.4023 34.1031 59.9324 28.74 59.9997C24.3933 59.939 20.1045 58.994 16.135 57.2219Z" fill="white"/>
                                </svg>
                                <svg className={classes.social_icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" fill="#0b818e"/></svg>

                                <span className='sr-only'>twitter</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/greatamericancookies/" target='_blank' rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="60" viewBox="0 0 62 60" fill="none">                                    
                                    <path d="M16.135 57.2219C6.28204 53.0389 0.567018 46.2597 1.02402 36.7697C1.42358 29.5679 2.95154 22.4736 5.55203 15.7458C10.794 2.11078 18.317 -1.30056 36.052 0.405444C55.644 2.28344 62.4241 9.26683 60.7621 25.8058C60.2371 37.9708 56.072 48.3869 43.903 55.5739C39.346 58.4023 34.1031 59.9324 28.74 59.9997C24.3933 59.939 20.1045 58.994 16.135 57.2219Z" fill="white"/>
                                </svg>
                                <svg className={classes.social_icon} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                    <path d="M7.5 0C3.4 0 0 3.4 0 7.5V18.4C0 22.6 3.4 26 7.5 26H18.4C22.6 26 25.9 22.6 25.9 18.5V7.5C26 3.4 22.6 0 18.5 0H7.5ZM7.5 2H18.4C21.5 2 24 4.5 24 7.5V18.4C24 21.5 21.5 23.9 18.5 23.9H7.5C4.5 24 2 21.5 2 18.5V7.5C2 4.5 4.5 2 7.5 2ZM20.5 4C19.7 4 19 4.7 19 5.5C19 6.3 19.7 7 20.5 7C21.3 7 22 6.3 22 5.5C22 4.7 21.3 4 20.5 4ZM13 6C9.1 6 6 9.1 6 13C6 16.9 9.1 20 13 20C16.9 20 20 16.9 20 13C20 9.1 16.9 6 13 6ZM13 8C15.8 8 18 10.2 18 13C18 15.8 15.8 18 13 18C10.2 18 8 15.8 8 13C8 10.2 10.2 8 13 8Z" fill="#0b818e"/>
                                </svg>
                                <span className='sr-only'>instagram</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@greatamericancookies" target='_blank' rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="60" viewBox="0 0 62 60" fill="none">                                    
                                    <path d="M16.135 57.2219C6.28204 53.0389 0.567018 46.2597 1.02402 36.7697C1.42358 29.5679 2.95154 22.4736 5.55203 15.7458C10.794 2.11078 18.317 -1.30056 36.052 0.405444C55.644 2.28344 62.4241 9.26683 60.7621 25.8058C60.2371 37.9708 56.072 48.3869 43.903 55.5739C39.346 58.4023 34.1031 59.9324 28.74 59.9997C24.3933 59.939 20.1045 58.994 16.135 57.2219Z" fill="white"/>
                                </svg>
                                <svg className={classes.social_icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" fill="#0b818e"/></svg>

                                <span className='sr-only'>tiktok</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className={classes.footer_bottom}>
            <div className='container-full'>
                <div className={classes.footer_bottom_inner}>
                    Mars, Incorporated has no affiliation and has no participation in the production or distribution of this product. M&M'S® is a registered trademark of Mars, Incorporated 2022.
                </div>
            </div>
        </div>
    </footer>
}

export default Footer