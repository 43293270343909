import { useDispatch } from "react-redux"
import { variousActions } from "../../store/various"

import classes from './AccountLanding.module.css'

import { useEffect, useState } from "react"
import { getUserFavoriteLocation } from "../../scripts/fetchApi"

import FavoriteLoc from "./FavoriteLoc"
import { getGenericToken } from "../../scripts/helpers"

const FavoriteLocations = () => {
    const dispatch = useDispatch()
    const [favorites, setFavorites] = useState()
    // const token = localStorage.getItem('token')
    // const token = sessionStorage.getItem('token')
    const token = getGenericToken()
    
    const [loading, setLoading] = useState(true)
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        const getFav = async () => {
            const fav = await getUserFavoriteLocation(token)

            if (fav.message) {                
                dispatch(variousActions.setError(fav))     
                setLoading(false)
            } else {
                setLoading(false)
                setFavorites(fav)
            }
        }
        getFav()

    }, [dispatch, token, flag])

    const removeLocHandler = () => {
        setFlag(prevState => prevState = !prevState)
    }

    return <div className={classes.favorite_orders}>
        {loading && <div className='preparing_restaurant_loader'></div>}
        {favorites && !loading && favorites.length > 0 && <div className={`grid-3`}>
            {favorites.map(item => (
                <FavoriteLoc key={item.id} item={item} removeLoc={removeLocHandler}/>
            ))}
        </div>}
        {favorites && !loading && favorites.length === 0 && <div>You haven't selected any favorite location</div>}
    </div>
}

export default FavoriteLocations