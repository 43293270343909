import classes from './ModifierTitle.module.css'
import OptionsHeaderText from '../products_details_v2/OptionsHeaderText'

const ModifierTitle = ({modifier}) => {

    return <div className={`${classes.options_item_header}`}>
        <div className={classes.options_item_header_text}>
            <h3>
                <OptionsHeaderText text={modifier.description}/>
                {/* {modifier.maxselects && !modifier.maxaggregatequantity && <span className={classes.options_item_header_helper}> Select up to {modifier.maxselects}</span>}
                {modifier.maxselects && modifier.maxaggregatequantity && <span className={classes.options_item_header_helper}> Select a total of {modifier.maxselects}</span>}  */}
                {/* no aggregate  */}
                {/* {modifier.maxselects && !modifier.maxaggregatequantity && <span className={classes.options_item_header_helper}> Select up to {modifier.maxselects}</span>} */}
                {modifier.maxselects && !modifier.maxaggregatequantity && modifier.minselects && modifier.minselects !== "0" &&<span className={classes.options_item_header_helper}> Select {modifier.minselects}</span>}
                {modifier.maxselects && !modifier.maxaggregatequantity && modifier.minselects && modifier.minselects === "0" &&<span className={classes.options_item_header_helper}> Select {modifier.maxselects}</span>}
                {modifier.maxselects && !modifier.maxaggregatequantity && !modifier.minselects && <span className={classes.options_item_header_helper}> Select up to {modifier.maxselects}</span>}


                {/* aggregate  */}
                {modifier.maxselects && modifier.maxaggregatequantity && <span className={classes.options_item_header_helper}> Select a total of {modifier.maxselects}</span>} 
            </h3>
            
        </div>
    </div>
}

export default ModifierTitle