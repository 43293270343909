import classes from './PresentationBox.module.css'
import { getItemFromStorage } from '../../../scripts/localStorage'

const PresentationBox = () => { 
    
    const product = getItemFromStorage('foundProductMarble')?.product

    return <div className={classes.presentation_box}>
        {product && <>
            {product.name && product.name !== '' && <h1 className={classes.page_title}>{product.name}</h1>}
        
            {product.description && product.description !== '' && <div className={classes.description}>{product.description}</div>}
            
            {product.image_large_url && <div className={classes.composite_image}>
                <div className={classes.composite_image_inner}>
                    <img src={product.image_large_url} alt={product.name}/>
                </div>
            </div>}
        </>}
    </div>
}

export default PresentationBox