import { useDispatch, useSelector } from 'react-redux'
import { locationActions } from '../../store/location'
import classes from './BannerSlider.module.css'
import '@splidejs/react-splide/css';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';



import image from '../../assets/HBD_Evergreen_Transparent.webp'
// import imageCoBrand from '../../assets/GAC_MSC_Hero.webp'
import { useNavigate } from 'react-router-dom'

const cncimages =  require.context('../../assets/slider/cnc', true)

const cncSlider = [{
    image: 'HBD_Evergreen_Transparent',
    subtitle: 'Celebrate Birthdays',
    title: 'WITH A COOKIE CAKE',
    description: 'Order a Custom Birthday Cookie Cake',
    link: {
        category: 'cookie-cakes',      
    }
}, {
    image: '212GAC_MDG_Webslider-MOM',
    subtitle: 'Because Mom',
    title: 'Deserves The Best',
    description: 'Treat Mom to a Fresh-Baked Cookie Cake!',
    link: {
        category: 'cookie-cakes',
        subcategory: 'Holidays'        
    }
}, {
    image: '212GAC_MDG_Webslider-GRAD',
    subtitle: 'Celebrate',
    title: 'Sweet Success',
    description: 'with a Graduation Cookie Cake! ',
    link: {
        category: 'cookie-cakes',
        subcategory: 'School & Graduation'        
    }
}]

const nonCncSlider = [{
    image: 'HBD_Evergreen_Transparent',
    subtitle: 'Celebrate Birthdays',
    title: 'WITH A COOKIE CAKE',
    description: 'Order a Custom Birthday Cookie Cake',
    link: {
        category: 'cookie-cakes',      
    }
}, {
    image: '212GAC_MDG_Webslider-MOM',
    subtitle: 'Because Mom',
    title: 'Deserves The Best',
    description: 'Treat Mom to a Fresh-Baked Cookie Cake!',
    link: {
        category: 'cookie-cakes',
        subcategory: 'Holidays'        
    }
}, {
    image: '212GAC_MDG_Webslider-GRAD',
    subtitle: 'Celebrate',
    title: 'Sweet Success',
    description: 'with a Graduation Cookie Cake! ',
    link: {
        category: 'cookie-cakes',
        subcategory: 'School & Graduation'        
    }
}]

// const nonCncSlider = [{
//     image: 'HBD_Evergreen_Transparent',
//     subtitle: 'Celebrate Birthdays',
//     title: 'WITH A COOKIE CAKE',
//     description: 'Order a Custom Birthday Cookie Cake',
// }, {
//     image: '212GAC_BlueberryCookie_Webslider-Artboard-1',
//     subtitle: 'A fan favorite is back!',
//     title: 'Enjoy a Dozen Blueberry Muffin Cookies',
// }, {
//     image: '212GAC_DoubleDoozie_Webslider-reflection',
//     title: 'Double the Delicious with a Double Doozie®',
//     description: 'Pair your favorite Cookies with our Decadent Icing',
// }]



const BannerSlider = ({noLocation}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const cncimagesList = cncimages.keys().map(image => cncimages(image));

    let locationStore = useSelector(state => state.location.location)

    const openLocationSidebar = () => {
        dispatch(locationActions.setLocationsSideBarState())        
    }

    // const navigateTohandler = () => {
    //     navigate('cookie-cakes', { state: { toHolidays: 'Holidays' } })
    // }
    const navigateTohandler = (link) => {
        // navigate('cookie-cakes', { state: { toHolidays: 'Holidays' } })
        console.log('link:::: ', link)
        if (link.subcategory) {
            navigate('cookie-cakes', { state: { toHolidays: link.subcategory } })
        } else {
            navigate(link.category)
        }
    }
    


    return <div className={`${classes.banner} banner-slider`}>
        <div className={classes.banner_background}>
            {locationStore && locationStore.cobranded && <>
                <Splide className='splide-custom-arrows' aria-label="Rewards" hasTrack={ false } options={ {
                    rewind: false,
                    perPage: 1,
                    fade: true,
                    pagination: true,
                    gap   : '0rem',
                    arrows: false,
                } }>
                    <SplideTrack>
                        {cncSlider.map(slide => (
                            <SplideSlide key={slide.title} className={classes.banner_slide}>
                                <div className={classes.banner_slide_inner}>
                                    <div className='container-full'>
                                        <div className={classes.banner_inner}>
                                            <div className={classes.banner_text}>
                                                <div className={classes.banner_text_inner}>
                                                    {slide.subtitle && <div className={classes.subtitle}>{slide.subtitle}</div>}
                                                    {slide.title && <h1 className={classes.title}>{slide.title}</h1>}
                                                    {slide.description && <div className={classes.description}>{slide.description}</div>}
                                                    <div className={classes.banner_text_footer}>
                                                        {!noLocation && <button to="cookie-cakes" className='btn-fancy' onClick={navigateTohandler.bind(this, slide.link)}>Order Now</button>}
                                                        {noLocation && <button to="cookie-cakes" className='btn-fancy' onClick={openLocationSidebar}>Order Now</button>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.banner_image}>
                                                <img src={cncimagesList.find(item => item.includes(slide.image))} alt='banner' className='image-contain' width={754} height={871}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                        ))}
                    </SplideTrack>
                    <div className={classes.custom_splide_pagination}>
                        <ul className="splide__pagination"></ul>
                    </div>
                    
                </Splide>
            </>}
            {(!locationStore || (locationStore && !locationStore.cobranded)) && <>
                <Splide className='splide-custom-arrows' aria-label="Rewards" hasTrack={ false } options={ {
                    rewind: false,
                    perPage: 1,
                    fade: true,
                    pagination: true,
                    gap   : '0rem',
                    arrows: false,
                } }>
                    <SplideTrack>
                        {nonCncSlider.map(slide => (
                            <SplideSlide key={slide.title} className={classes.banner_slide}>
                                <div className={classes.banner_slide_inner}>
                                    <div className='container-full'>
                                        <div className={classes.banner_inner}>
                                            <div className={classes.banner_text}>
                                                <div className={classes.banner_text_inner}>
                                                    {slide.subtitle && <div className={classes.subtitle}>{slide.subtitle}</div>}
                                                    {slide.title && <h1 className={classes.title}>{slide.title}</h1>}
                                                    {slide.description && <div className={classes.description}>{slide.description}</div>}
                                                    <div className={classes.banner_text_footer}>
                                                        {!noLocation && <button to="cookie-cakes" className='btn-fancy' onClick={navigateTohandler.bind(this, slide.link)}>Order Now</button>}
                                                        {noLocation && <button to="cookie-cakes" className='btn-fancy' onClick={openLocationSidebar}>Order Now</button>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.banner_image}>
                                                <img src={cncimagesList.find(item => item.includes(slide.image))} alt='banner' className='image-contain' width={754} height={871}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                        ))}
                    </SplideTrack>
                    <div className={classes.custom_splide_pagination}>
                        <ul className="splide__pagination"></ul>
                    </div>
                    
                </Splide>
            </>}
        </div>
    </div>
}

export default BannerSlider