import { useEffect } from 'react';
import Card from '../fragments/Card'
import classes from './SuperCategories.module.css'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { locationActions } from '../../store/location';
import { getItemFromStorage } from '../../scripts/localStorage';


import placeholderImage from '../../assets/combined-logos-placeholder.png'
import SetFavoriteLocation from '../common/SetFavoriteLocation';

import ArrowSliderRightIcon from '../../icons/arrowsliderright';
import { getGenericToken } from '../../scripts/helpers';


const SuperCategories = () => {
    const dispatch = useDispatch();
    const storage = getItemFromStorage('currentLocationMarble')

    const token = getGenericToken()
    useEffect(() => {
        
        if (storage) {
            dispatch(locationActions.setLocationItem(storage.currentLocationMarble))
        }
        const menu = getItemFromStorage('currentMenuMarble')?.menu
        if (menu) {
            dispatch(locationActions.setMenuItem(menu))
        }
    }, [dispatch])

    const location = useSelector(state => state.location.location)

    const menu = useSelector(state => state.location.menu)

    const openLocationSidebar = () => {
        dispatch(locationActions.setLocationsSideBarState())        
    }

    return <section>
        {token && location && location.id && <div className={classes.set_fav_loc_container}>
            <SetFavoriteLocation data={location} />    
        </div>}
        <div className="container-full">
            <div className={`grid-3 ${classes.supercategories}`}>
                {!location && !storage && <>
                    {menu && menu.map(item => (                                                        
                        <Card key={item.id + Math.random(10)} direction="column" extraClass="item_no_padding">
                            <button onClick={openLocationSidebar} className={classes.button_link}>
                                <div className={classes.image_container}>
                                    {item.image && <img src={item.image} className='image-cover' alt={item.name} loading="lazy" width={524} height={300}/>}
                                    {!item.image && <img src={placeholderImage} className='image-contain' alt={item.name} loading="lazy" width={524} height={300}/>}
                                </div>
                                <div className={classes.text_content}>
                                    <div className={classes.title}>
                                        {item.name}
                                        <span className={classes.title_icon}><ArrowSliderRightIcon /></span>
                                    </div>
                                </div>
                            </button>
                        </Card>
                    ))}
                </>}
                {location && <>
                    {menu && menu.categories && menu.categories.filter(item => (item.products && item.products.length > 0) || (item.sub_categories && item.sub_categories.length > 0)).map(item => (                                                        
                        <Card key={item.id + Math.random(10)} direction="column" extraClass="item_no_padding">
                            <Link to={`${item.slug}`} className={classes.button_link}>
                                <div className={classes.image_container}>
                                    {item.image && <img src={item.image} className='image-cover' alt={item.name} loading="lazy" width={524} height={300}/>}
                                    {!item.image && <img src={placeholderImage} className='image-contain' alt={item.name} loading="lazy" width={524} height={300}/>}
                                </div>
                                <div className={classes.text_content}>
                                    <div className={classes.title}>
                                        {item.name}
                                        <span className={classes.title_icon}><ArrowSliderRightIcon /></span>
                                    </div>
                                </div>
                            </Link>
                        </Card>
                    ))}
                </>}   
            </div>      
        </div>

    </section>
}

export default SuperCategories