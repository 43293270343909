import { createSlice } from "@reduxjs/toolkit";

const initialCartState = {
    cart: null,
    numberOfProducts: 0,
    totalPrice: 0,
    cartSidebarOpen: false,
    items: [],
    vehicleInfo: {
        vehicleMake: null,
        vehicleModel: null,
        vehicleColor: null   
    },
    billing: null,
    digitalWallet: null,
    cartSidebarStep: 'order',
    tipSelection: null,
    hasUserOptedIn: true,
}

const cartSlice = createSlice({
    name: 'cart',
    initialState: initialCartState,
    reducers: {
        addCart(state, data) {
            state.cart = data.payload
        },
        updateVehicleinfo(state, data) {
            state.vehicleInfo[data.payload.handle] = data.payload.value
        },
        setVehicleInfo(state, data) {
            state.vehicleInfo = data.payload
        },
        incrementProducts(state) {
            state.numberOfProducts++
        },
        decrementProducts(state) {
            state.numberOfProducts--
        },
        setCartSidebarState(state) {
            state.cartSidebarOpen = !state.cartSidebarOpen
        },
        addItem(state, item) {
            const index = state.items.findIndex(object => {
                return object.id == item.payload.id
            })
          
            if (index < 0) {
                state.items.push(item.payload)
            } else {
                state.items[index].amount = state.items[index].amount + 1
            }
        }, 
        removeItem(state, id) {
            const index = state.items.findIndex(object => {
                return object.id == id.payload
            })  
            state.items.splice(index, 1)
        },
        addOneItem(state, id) {
            const index = state.items.findIndex(object => {
                return object.id == id.payload
            })

            state.items[index].amount = state.items[index].amount + 1
        },
        removeOneItem(state, id) {
            const index = state.items.findIndex(object => {
                return object.id == id.payload
            })
            if (state.items[index].amount > 1) {
                state.items[index].amount = state.items[index].amount - 1
            } else {
                state.items.splice(index, 1)
            }
        },
        setBilling(state, data) {
            state.billing = data.payload
        },
        removeBillingMethod(state, data) {
            state.billing = null
        },
        setDigitalWallet(state, data) {
            state.digitalWallet = data.payload
        },
        setCartSidebarStep(state, step) {
            state.cartSidebarStep = step.payload
        },
        setTipSelection(state, selection) {
            state.tipSelection = selection.payload
        },
        setHasUserOptedIn(state, data) {
            state.hasUserOptedIn = data.payload
        },
    }
})

export const cartActions = cartSlice.actions

export default cartSlice.reducer