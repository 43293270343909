import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getItemFromStorage, setSessionStorageItem } from '../../../scripts/localStorage'
import { getAuthToken } from '../../../scripts/auth'
import { locationActions } from '../../../store/location'
import { updateProductInCart, addProductToExistingCart, addProductToCartAndCreateCart, addProductToExistingCartUpdate } from '../../../scripts/fetchApi'
import TotalPrice from './TotalPrice'

import classes from './AddToCart.module.css'
import { cartActions } from '../../../store/cart'
import { variousActions } from '../../../store/various'
import { useLocation } from 'react-router-dom'
import { getGenericToken } from '../../../scripts/helpers'


function getSelectedOptions(data) {
    const selectedOptions =[]
    function getSelectedOptions(data, flag) {
        data.forEach(element => {
            element.options.forEach(option => {
                if (option.itemSelected) {
                    selectedOptions.push(option)                    
                }
                if (option.modifiers) {
                    getSelectedOptions(option.modifiers)
                }
            })
        });
    }

    getSelectedOptions(data)

    return selectedOptions
}

function returnErrors(arr) {
    let errors =[]
    arr.forEach(element => {
        if (element.mandatory && !element.itemSelected) {
            errors.push(element)
        }
    });

    return errors
}

const AddToCart = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const hasLocation = useSelector(state => state.location.location)
    const productDetails = useSelector(state => state.productDetails.data)

    const isCart = useSelector(state => state.cart.cart)
    const optionsAreConfirmed = useSelector(state => state.product.optionsConfirmed)

    const product = getItemFromStorage('foundProductMarble')?.product
    let itemData = product

    const [productQuantity, setproductQuantity] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [btnWidth, setBtnWidth] = useState(null)

    const addOneItemHandler = () => {
        setproductQuantity(prevState => prevState = prevState + 1)
    }

    const removeOneItemHandler = () => {
        if (productQuantity > 1) {
            setproductQuantity(prevState => prevState = prevState - 1)
        }
    }

    const openLocationsSidebar = () => {
        dispatch(locationActions.setLocationsSideBarState())
    }


    const addToCartHandler = async (e) => {
        setIsLoading(true)
        setBtnWidth(e.target.getBoundingClientRect().width)
        const selectedOptions = getSelectedOptions(productDetails)
        let specialInstructions = ''
        if (document.getElementById('orderCustomMessageTextareaInput') && document.getElementById('orderCustomMessageTextareaInput').value.trim() !== "" ) {
            specialInstructions = "Custom Message: " + document.getElementById('orderCustomMessageTextareaInput').value
        }

        if (document.getElementById('orderCustomDesignTextareaInput') && document.getElementById('orderCustomDesignTextareaInput').value.trim() !== "" ) {
            specialInstructions = specialInstructions + ' | Custom Design: ' + document.getElementById('orderCustomDesignTextareaInput').value
        }


        if (returnErrors(productDetails).length > 0) {
            returnErrors(productDetails).forEach(element => {
                dispatch(variousActions.setError({
                    message: `You need to select an option for ${element.description.replace(/[^a-zA-Z /]+/g, '')}`
                }))
            });
        } else {
            if (optionsAreConfirmed) {

                let choicesArr = []
                let productTotal = 0
                selectedOptions.forEach(item => {
                    choicesArr.push({
                        choiceid: item.id,
                        quantity: item.selectedQuantity ? item.selectedQuantity : 1
                    })
                    productTotal += item.cost
                })

                const restaurantId = hasLocation.id

                const reqBody = {
                    "add_by": "batch",
                    restaurant_id: restaurantId,
                    quantity: productQuantity,
                    product_id: itemData.id,
                    special_instructions: specialInstructions,
                    choices: choicesArr
                    // options: options.join(','),
                    // choice_custom_fields: choice_custom_fields
                }

                const updatedproductReqBody = {
                    "add_by": "batch",
                    quantity: productQuantity,
                    product_id: itemData.id,
                    special_instructions: specialInstructions,
                    choices: choicesArr
                }

                // let token = getAuthToken()

                let token = getGenericToken()

                if (token) {
                    reqBody.authtoken = token
                    updatedproductReqBody.authtoken = token
                } 


                if (isCart) { // there are products in cart!

                        if (location.state && location.state.type) {
                            //is a edit page!!!!
                            updatedproductReqBody.product_id = location.state.productInCart

                            const updatedProduct = await addProductToExistingCartUpdate(isCart.id, updatedproductReqBody)

                            if (updatedProduct.id) {                
                                setSessionStorageItem('currentCart', updatedProduct)
                                dispatch(cartActions.addCart(updatedProduct))
                                dispatch(cartActions.setCartSidebarStep("order"))
                                dispatch(cartActions.setCartSidebarState())                 
                            } else {
                                dispatch(variousActions.setError(updatedProduct))
                            }
                        } else {
                            // is not an edit page !!!! 

                            const addToCart = await addProductToExistingCart(isCart.id, reqBody)
                            if (addToCart.id) {
                                setSessionStorageItem('currentCart', addToCart)
                                dispatch(cartActions.addCart(addToCart))
                                dispatch(cartActions.setCartSidebarStep("order"))
                                dispatch(cartActions.setCartSidebarState()) 
                                
                            } else {
                                dispatch(variousActions.setError(addToCart)) 
                            }
                        }

                } else {   // there is no cart

                    const cart = await addProductToCartAndCreateCart(reqBody) 
                    
                    
                    if (cart.id) {   
                        setSessionStorageItem('currentCart', cart)
                        dispatch(cartActions.addCart(cart))
                        dispatch(cartActions.setCartSidebarStep("order"))
                        dispatch(cartActions.setCartSidebarState())  
                    } else {
                        dispatch(variousActions.setError(cart)) 
                    }
                }

                // START GTM DATA
                if  (product) {
                    window.dataLayer = window.dataLayer || [];

                    
                    window.dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        currency: "USD", // e.g. "USD",
                        value: productTotal, // e.g. 7.77,
                        items: [{
                            item_id: product.id, 
                            item_name: product.name, 
                            // coupon: null, 
                            // discount: null, // e.g. 2.22,
                            // item_category: <item category>, // e.g. "Apparel",
                            // item_list_id: <item list id>, // e.g. "related_products",
                            // item_list_name: <item list name>, // e.g. "Related Products",
                            price: productTotal, // e.g. 9.99,
                            quantity: productQuantity, // e.g. 1
                        }]
                    }
                    });
                }
                // END GTM DATA

            } else {
                dispatch(variousActions.setError({message: 'Please confirm your selections!'}))
            }
        }

        setIsLoading(false)
    }


    return <>
        {hasLocation && <div className={classes.product_options_footer}>
            <div className={classes.product_options_footer_inner}>
                <div className={classes.product_options_footer_controls}>
                    <button className={`${classes.control_button} ${classes.control_button_decrement}`} onClick={removeOneItemHandler} disabled={productQuantity === 1 ? true : false}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>
                        </span>
                    </button>
                    <span className={classes.controls_display}>{productQuantity}</span>
                    <button className={`${classes.control_button} ${classes.control_button_increment}`} onClick={addOneItemHandler} >
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                        </span>
                    </button>
                </div>
                {!isLoading && <button onClick={addToCartHandler} className='btn btn-large'>
                    <span>Add To Cart</span>
                    <span>|</span>
                    <TotalPrice productQuantity={productQuantity}/>
                </button>}
                
                {isLoading && <div className='btn-loader' style={{width: btnWidth}}><span className="loader"></span></div>}
            </div>
        </div>}

        {!hasLocation && <div className={classes.product_options_footer}>
            <div className={classes.product_options_footer_inner}>
                <button className='btn btn-large' onClick={openLocationsSidebar}>                    
                    <span>Find your store</span>
                    <span>|</span>
                    <TotalPrice productQuantity={productQuantity}/>
                </button>
            </div>
        </div>}
    </>
}

export default AddToCart