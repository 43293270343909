import { useLoaderData, Await } from "react-router-dom"
import { Suspense } from "react"


import LoadingWrapperV2 from "../components/common/LoadingWrapperV2"
import FavoritesLanding from "../components/account/Favorites"

const Favorites = () => {
    const data = useLoaderData() 
     
    return <Suspense fallback={<LoadingWrapperV2 text="Loading Account"/>}>
        <Await resolve={data.data}>
            {() => <>
                <FavoritesLanding/>
            </>}
        </Await>
    </Suspense>
}

export default Favorites