import { redirect } from "react-router-dom"
import { getuserInfo } from "./fetchApi"
// import { setStorageItem, getItemFromStorage } from "./localStorage"

export const getTokenDuration = (expiration) => {
    const storedExpirationDate = localStorage.getItem(expiration)
    const expirationDate = new Date(storedExpirationDate)
    const now = new Date()
    const duration = expirationDate.getTime() - now.getTime()
    return duration
}

export const getAuthToken = () => {
    const token = localStorage.getItem('token')

    if (!token) {
        return null
    }


    // remove the expiration 
    // const tokenDuration = getTokenDuration('expiration')
    // if (tokenDuration < 0) {
    //     localStorage.removeItem('token')
    //     return 'EXPIRED'
    // }

    return token
}


export const tokenLoader = () => {
    return getAuthToken()
}

export const checkAuthLoader = () => {
    const token = getAuthToken()
    // const storage = getItemFromStorage('currentUser')
    const storage = JSON.parse(localStorage.getItem("currentUser"));
    if (token) {
        if (storage === null) {
            const fetchUser = async () => {
                const user = await getuserInfo(token)            
                if (user.id) {
                    localStorage.setItem("currentUser", JSON.stringify(user)); 
                    return user
                }
                return redirect('/signin')
            }
    
            const data = fetchUser()
            
            return data
        } else if (storage.first_name) {
            return storage
        }
        return redirect('/signin')
    }

    if (!token) {     
        return redirect('/signin')
    }

    return null
} 