import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import classes from './BuilderImage.module.css'
import shapeSquare from '../../../../assets/base/square/Original-Chocolate-Chip.jpg'
import shapeRound from '../../../../assets/base/round/Original-Chocolate-Chip.jpg'
import shapeRect from '../../../../assets/base/rect/Original-Chocolate-Chip.jpg'

import shapeSquare_mm from '../../../../assets/base/square/m-original-chocolate-chip-mm.jpg'
import shapeRound_mm from '../../../../assets/base/round/m-original-chocolate-chip-mm.jpg'
import shapeRect_mm from '../../../../assets/base/rect/m-original-chocolate-chip-mm.jpg'


import IndividualImageBuilder from "./IndividualImageBuilder"
import ImagesContainer from "./ImagesContainer"

let selectedShape = 'square'
const squareBaseImages = require.context('../../../../assets/base/square/', true)
const squareBaseImagesList = squareBaseImages.keys().map(image => squareBaseImages(image));

const rectBaseImages = require.context('../../../../assets/base/rect/', true)
const rectBaseImagesList = rectBaseImages.keys().map(image => rectBaseImages(image));

const roundBaseImages = require.context('../../../../assets/base/round/', true)
const roundBaseImagesList = roundBaseImages.keys().map(image => roundBaseImages(image));

const redRoundDollops = require.context('../../../../assets/dollops/round/red', true)
const redRoundDollopsList = redRoundDollops.keys().map(image => redRoundDollops(image));

const whiteRoundDollops = require.context('../../../../assets/dollops/round/white', true)
const whiteRoundDollopsList = whiteRoundDollops.keys().map(image => whiteRoundDollops(image));


const redSquareDollops = require.context('../../../../assets/dollops/square/red', true)
const redSquareDollopsList = redSquareDollops.keys().map(image => redSquareDollops(image));

const whiteSquareDollops = require.context('../../../../assets/dollops/square/white', true)
const whiteSquareDollopsList = whiteSquareDollops.keys().map(image => whiteSquareDollops(image));

const redRectDollops = require.context('../../../../assets/dollops/rect/red', true)
const redRectDollopsList = redRectDollops.keys().map(image => redRectDollops(image));

const whiteRectDollops = require.context('../../../../assets/dollops/rect/white', true)
const whiteRectDollopsList = whiteRectDollops.keys().map(image => whiteRectDollops(image));

const colorArr = [
    {
        color: 'Vanilla',
        noChange: true,
        hue: null,
        base: 'white',
        hsl: null,        
    }, {
        color: 'Chocolate',
        hue: 'hue-rotate(10deg)',
        base: 'red',
        hsl: 'hsl(22, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(19%) sepia(13%) saturate(2437%) hue-rotate(341deg) brightness(97%) contrast(96%)'
        filter: 'hue-rotate(39deg) saturate(1) brightness(.6)'
    }, {
        color: 'Red',
        noChange: true,
        hue: null,
        base: 'red',
        hsl: null,
    }, {
        color: 'Blue',
        hue: 'hue-rotate(190deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(51%) sepia(73%) saturate(350%) hue-rotate(158deg) brightness(99%) contrast(97%)'
        filter: 'hue-rotate(207deg) saturate(1.2) brightness(2.2)'
    }, {
        color: 'Orange',
        hue: 'hue-rotate(30deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(30%) sepia(97%) saturate(1244%) hue-rotate(347deg) brightness(99%) contrast(91%)'
        filter: 'hue-rotate(28deg) saturate(1.8) brightness(2.8)'
    }, {
        color: 'Green',
        hue: 'hue-rotate(90deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(57%) sepia(28%) saturate(916%) hue-rotate(47deg) brightness(95%) contrast(91%)'
        filter: 'hue-rotate(99deg) saturate(.6) brightness(2.5)'
    }, {
        color: 'Pink',
        hue: 'hue-rotate(300deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(40%) sepia(60%) saturate(3645%) hue-rotate(326deg) brightness(98%) contrast(82%)'
        filter: 'hue-rotate(353deg) saturate(1) brightness(2.3)'
    }, {
        color: 'Yellow',
        hue: 'hue-rotate(60deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(86%) sepia(72%) saturate(429%) hue-rotate(353deg) brightness(93%) contrast(98%)'
        filter: 'hue-rotate(65deg) saturate(.7) brightness(3)'
    }, {
        color: 'Dark Purple',
        hue: 'hue-rotate(270deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(19%) sepia(10%) saturate(7356%) hue-rotate(219deg) brightness(97%) contrast(105%)'
        filter: 'hue-rotate(268deg) saturate(1.7) brightness(.8)'
    }, {
        color: 'Dark Green',
        hue: 'hue-rotate(120deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(43%) sepia(15%) saturate(1103%) hue-rotate(64deg) brightness(98%) contrast(95%)'
        filter: 'hue-rotate(123deg) saturate(.4) brightness(2)'
    }, {
        colorName: 'Dark Blue',
        hue: 'hue-rotate(120deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        filter: 'brightness(0) saturate(100%) invert(23%) sepia(28%) saturate(6293%) hue-rotate(193deg) brightness(97%) contrast(101%)'
    }
]

const textColor = [
    {
        colorName: 'Vanilla',
        color: 'hsl(49.3,87.5%,93.7%)',
        trueColor: "#fdf8e1",
    }, {
        colorName: 'Chocolate',
        color: 'hsl(24.2,59.6%,20.4%)',
        trueColor: "#532e15",
    }, {
        colorName: 'Red',
        color: 'hsl(358.9,68%,52.2%)',
        trueColor: "#d83235",
    }, {
        colorName: 'Blue',
        color: 'hsl(201.7,64.4%,57.1%)',
        trueColor: "#4ba5d8",
    }, {
        colorName: 'Orange',
        color: 'hsl(12.9,74.4%,51%)',
        trueColor: "#df4d25",
    }, {
        colorName: 'Green',
        color: 'hsl(89.7,52.6%,42.2%)',
        trueColor: "#6ca433",
    }, {
        colorName: 'Pink',
        color: 'hsl(349.7,73.1%,59.2%)',
        trueColor: "#e34b65",
    }, {
        colorName: 'Yellow',
        color: 'hsl(57.7,79.9%,61%)',
        trueColor: "#ebe54c",
    }, {
        colorName: 'Dark Purple',
        color: 'hsl(246.3,53.8%,31.4%)',
        trueColor: "#2e257b",
    }, {
        colorName: 'Dark Green',
        color: 'hsl(110.5,28.9%,38.6%)',
        trueColor: "#4f7f46",
    }, {
        colorName: 'Dark Blue',
        color: 'hsl(207.8,100%,34.7%)',
        trueColor: "#005fb1",
    }
]

const BuilderImageNoCanvas = ({mobile, selectedOptions}) => {

    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [isReady, setIsReady] = useState(false)
    const canvasContainerRef = useRef(null)

    const customText = useSelector(state => state.product.customMessage)

    const [wholeImage, setWholeImage] = useState([{
        uri: shapeSquare,
    }])

    let dollopsArray = []

    const [textColorObj, setTextColorObj] = useState({
        baseColor: 'white',
        topColor: 'black',
    })

    useEffect(() => {
        document.fonts.load("46px Intro Script Bold").then(() => setIsReady(true));
       
        const setCanvasSize = () => {
            setLoading(true)
            if (selectedOptions) {
                let wholeImageWhitoutDollops = [{
                    uri: shapeSquare
                }]
                let temporaryImage = [{
                    uri: shapeSquare
                }]
                
                selectedOptions.forEach(option => {
                    //START select base shape
                    if (option.modName.toLowerCase().includes("choose shape")) { 

                        if (option.selectedoptions.length > 0) {
                            option.selectedoptions.forEach(element => {
                                if (element.name.toLowerCase().includes('round')) {
                                    selectedShape = 'round'
                                    temporaryImage = [{
                                        uri: shapeRound,
                                    }]
                                }
                                if (element.name.toLowerCase().includes('square')) {
                                    selectedShape = 'square'
                                    temporaryImage = [{
                                        uri: shapeSquare,
                                    }]
                                }
                                if (element.name.toLowerCase().includes('rect')) {
                                    selectedShape = 'rect'
                                    temporaryImage = [{
                                        uri: shapeRect,
                                    }]
                                }
                                
                            });
                            wholeImageWhitoutDollops = temporaryImage
                        }
                        
                    }
                    //End select base shape

                    // START flavor
                    if (option.modName.toLowerCase().includes("choose cookie cake flavor")) {
                        
                        if (option.selectedoptions.length > 0) {
                            if (selectedShape === 'square') {                    
                                squareBaseImagesList.forEach(imgSrc => {
                                    if (imgSrc.includes(option.selectedoptions[0]?.name.split(" ").join("-"))) {
                                        temporaryImage = [{
                                            uri: imgSrc,
                                        }]
                                    }
                                })                   
                            }
                            if (selectedShape === 'rect') {                    
                                rectBaseImagesList.forEach(imgSrc => {
                                    if (imgSrc.includes(option.selectedoptions[0]?.name.split(" ").join("-"))) {
                                        temporaryImage = [{
                                            uri: imgSrc,
                                        }]
                                    
                                    }
                                })                   
                            }
                            if (selectedShape === 'round') {                    
                                roundBaseImagesList.forEach(imgSrc => {
                                    if (imgSrc.includes(option.selectedoptions[0]?.name.split(" ").join("-"))) {
                                        // wholeImage = [{
                                        //     uri: imgSrc,
                                        // }]
                                        // setWholeImage([{
                                        //     uri: imgSrc,
                                        // }])
                                        temporaryImage = [{
                                            uri: imgSrc,
                                        }]
                                    }
                                })                   
                            }
                        }                       


                        wholeImageWhitoutDollops = temporaryImage
                        
                    }
                    // END flavor
                })
                selectedOptions.forEach(option => {
                    // START m&m baked in
                    if (option.modName.toLowerCase().includes("upgrade your cookie")) {                        
                        if (option.selectedoptions.length > 0) {
                            option.selectedoptions.forEach(item => {
                                if (item.name === "M&Ms Baked In") {
                                    if (selectedShape === 'square') {                                                            
                                        temporaryImage = [{
                                            uri: shapeSquare_mm,
                                        }] 
                                    }
                                    if (selectedShape === 'rect') {                    
                                        temporaryImage = [{
                                            uri: shapeRect_mm,
                                        }]                  
                                    }
                                    if (selectedShape === 'round') {  
                                        temporaryImage = [{
                                            uri: shapeRound_mm,
                                        }]                    
                                    }    

                                    // START flavor
                                    selectedOptions.forEach(option => {
                                        if (option.modName.toLowerCase().includes("choose cookie cake flavor")) {
                                            if (selectedShape === 'square') {                 
                                                squareBaseImagesList.forEach(imgSrc => {
                                                    
                                                    if (imgSrc.includes(`m-${option.selectedoptions[0]?.name.split(" ").join("-").toLowerCase()}`)) {
                                                        temporaryImage = [{
                                                            uri: imgSrc,
                                                        }]
                                                    }
                                                })                   
                                            }
                                            if (selectedShape === 'rect') {                    
                                                rectBaseImagesList.forEach(imgSrc => {
                                                    if (imgSrc.includes(`m-${option.selectedoptions[0]?.name.split(" ").join("-").toLowerCase()}`)) {
                                                        temporaryImage = [{
                                                            uri: imgSrc,
                                                        }]
                                                    
                                                    }
                                                })                   
                                            }
                                            if (selectedShape === 'round') {                    
                                                roundBaseImagesList.forEach(imgSrc => {
                                                    if (imgSrc.includes(`m-${option.selectedoptions[0]?.name.split(" ").join("-").toLowerCase()}`)) {
                                                        temporaryImage = [{
                                                            uri: imgSrc,
                                                        }]
                                                    }
                                                })                   
                                            }                                        
                                        }
                                    })     
                                    // END flavor

                                    wholeImageWhitoutDollops = temporaryImage
                                }
                            })          
                        }     
                    } 
                    // END m&m baked in
                })
                selectedOptions.forEach(option => {
                    // START fully iced
                    if (option.modName.toLowerCase() === "choose flavor") {
                        
                        if (option.selectedoptions.length > 0) {
                            if (selectedShape === 'square') {                    
                                squareBaseImagesList.forEach(imgSrc => {
                                    if (imgSrc.includes(option.selectedoptions[0]?.name.split(" ").join("-"))) {
                                        temporaryImage = [{
                                            uri: imgSrc,
                                        }]
                                    }
                                })                   
                            }
                            if (selectedShape === 'rect') {                    
                                rectBaseImagesList.forEach(imgSrc => {
                                    if (imgSrc.includes(option.selectedoptions[0]?.name.split(" ").join("-"))) {
                                        temporaryImage = [{
                                            uri: imgSrc,
                                        }]
                                    
                                    }
                                })                   
                            }
                            if (selectedShape === 'round') {                    
                                roundBaseImagesList.forEach(imgSrc => {
                                    if (imgSrc.includes(option.selectedoptions[0]?.name.split(" ").join("-"))) {
                                        temporaryImage = [{
                                            uri: imgSrc,
                                        }]
                                    }
                                })                   
                            }
                        }     
                        wholeImageWhitoutDollops = temporaryImage
                    } 
                    // END fully iced

                    // START  border color
                    if (option.modName.toLowerCase().includes("border color")) {

                        if (option.selectedoptions.length > 0) {
                            dollopsArray = [] //clear the array first

                            option.selectedoptions.forEach(choice => {
                                dollopsArray.push({
                                    hue: colorArr.find(c => c.color === choice.name)?.hue,
                                    hsl: colorArr.find(c => c.color === choice.name)?.hsl,
                                    filter: colorArr.find(c => c.color === choice.name)?.filter,
                                    step: colorArr.find(c => c.color === choice.name)?.base,
                                    nocolor: colorArr.find(c => c.color === choice.name)?.base,
                                    name: colorArr.find(c => c.color === choice.name)?.color
                                })                                                    
                            })  
                            let redDollopsURLList = redRoundDollopsList
                            let whiteDollopsURLList = whiteRoundDollopsList
                            if (selectedShape === 'square') {                    
                                redDollopsURLList = redSquareDollopsList   
                                whiteDollopsURLList = whiteSquareDollopsList  
                            }
                            if (selectedShape === 'rect') {                    
                                redDollopsURLList = redRectDollopsList   
                                whiteDollopsURLList = whiteRectDollopsList 
                            }


                            let arr = dollopsArray.map((item, i) => {
                                let finalImage = {}
                                if (item.nocolor === 'red') {
                                    switch (i) {
                                        case 0:
                                            finalImage.uri = redDollopsURLList.find(item => item.includes("full"))
                                            break;
                                        case 1:
                                            finalImage.uri = dollopsArray.length === 2 ? redDollopsURLList.find(item => item.includes("2nd-simple")) : redDollopsURLList.find(item => item.includes("double-2"))
                                            break;
                                        case 2:
                                            finalImage.uri = redDollopsURLList.find(item => item.includes("double-3"))
                                            break;
                                        default:
                                            finalImage.uri = redDollopsURLList.find(item => item.includes("full"))
                                            break;
                                    }
                                } 
                                if (item.nocolor === 'white') {
                                    switch (i) {
                                        case 0:
                                            finalImage.uri = whiteDollopsURLList.find(item => item.includes("full"))
                                            break;
                                        case 1:
                                            finalImage.uri = dollopsArray.length === 2 ? whiteDollopsURLList.find(item => item.includes("2nd-simple")) : whiteDollopsURLList.find(item => item.includes("double-2"))
                                            break;
                                        case 2:
                                            finalImage.uri = whiteDollopsURLList.find(item => item.includes("double-3"))
                                            break;
                                        default:
                                            finalImage.uri = whiteRoundDollopsList.find(item => item.includes("full"))
                                            break;
                                    }
                                }                       
                                // finalImage.hue = item.hue
                                finalImage.hsl = item.hsl
                                finalImage.filter = item.filter
                                return finalImage;
                            })


                            // wholeImage = [...wholeImageWhitoutDollops, ...arr]
                            temporaryImage = [...wholeImageWhitoutDollops, ...arr]
                            // setWholeImage(p => p = [...p, ...arr])
                        }
                        
                        
                    }
                    // END  border color

                    // setWholeImage(temporaryImage)

                    // START base TEXT COLOR    
                    if (option.modName.toLowerCase().includes("base")) {                            
                        if (option.selectedoptions.length > 0) {
                            textColorObj.baseColor = textColor.find(c => c.colorName === option.selectedoptions[0].name).trueColor
                            setTextColorObj({
                                baseColor: textColor.find(c => c.colorName === option.selectedoptions[0].name).trueColor,
                                topColor: 'black',
                            })
                        }
                    }
                    // END base TEXT COLOR  

                    // START top TEXT COLOR    
                    if (option.modName.toLowerCase().includes("top")) {
                        if (option.selectedoptions.length > 0) {
                            // textColorObj.topColor = textColor.find(c => c.colorName === option.selectedoptions[0].name).trueColor
                            setTextColorObj({
                                baseColor: textColorObj.baseColor,
                                topColor: textColor.find(c => c.colorName === option.selectedoptions[0].name).trueColor,
                            })
                        } 
                    } 
                    // END top TEXT COLOR 

                })

                setWholeImage(temporaryImage)
            }           
            setLoading(false)
        }

        setCanvasSize()
        window.onresize = setCanvasSize
       
        
    }, [selectedOptions, mobile, isReady, customText, location])


    return  <div className={classes.container} ref={canvasContainerRef}>
        <ImagesContainer wholeImage={wholeImage}/>
        {customText && <div className={classes.text_container} style={{color: textColorObj.topColor, textShadow: `${textColorObj.baseColor} 0px 0px 0px, ${textColorObj.baseColor} 1px 0px 0px,
        ${textColorObj.baseColor} 2px 0px 0px, ${textColorObj.baseColor} 3px 1px 0px,
        rgba(0, 0, 0, 0.13) 4px -4px 3px, rgba(0, 0, 0, 0.5) 3px -1px 5px,
        rgba(0, 0, 0, 0.2) 5px 3px 3px`}}>{customText}</div>}
        {!customText && <div className={classes.text_container} style={{color: textColorObj.topColor, textShadow: `${textColorObj.baseColor} 0px 0px 0px, ${textColorObj.baseColor} 1px 0px 0px,
        ${textColorObj.baseColor} 2px 0px 0px, ${textColorObj.baseColor} 3px 1px 0px,
        rgba(0, 0, 0, 0.13) 4px -4px 3px, rgba(0, 0, 0, 0.5) 3px -1px 5px,
        rgba(0, 0, 0, 0.2) 5px 3px 3px`}}>Your Message Here</div>}
    </div>  
    
}

export default BuilderImageNoCanvas