import { useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { variousActions } from "../../store/various"
import { updateUserInfo } from "../../scripts/fetchApi"
import { authActions} from "../../store/auth"
import { useSelector } from "react-redux"
import { getGenericToken, isEmail, isEmpty } from "../../scripts/helpers"




const EditAccount = () => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const user = useSelector(state => state.auth.user)

    // const token = localStorage.getItem('token')
    // const token = sessionStorage.getItem('token')
    const token = getGenericToken()

    const emailInputRef = useRef(null)
    const phoneInputRef = useRef(null)
    const firstNameInputRef = useRef(null)
    const lastNameInputRef = useRef(null)

    

    const [formInputsValidity, setFormInputsValidity] = useState({
        email: true,
        phone: true,
        firstName: true,
        lastName: true,
    })

    const signUpHandler = async (event) => {
        event.preventDefault()
        

        const enteredEmail = emailInputRef.current.value
        const enteredPhone = phoneInputRef.current.value
        const enteredFirstName = firstNameInputRef.current.value
        const enteredLastName = lastNameInputRef.current.value


        const enteredEmailisValid = !isEmpty(enteredEmail) && isEmail(enteredEmail)
        const enteredPhoneIsValid = !isEmpty(enteredPhone)
        const enteredFirstNameIsValid = !isEmpty(enteredFirstName)
        const enteredLastNameIsValid = !isEmpty(enteredLastName)

        setFormInputsValidity({
            email: enteredEmailisValid,
            phone: enteredPhoneIsValid,
            firstName: enteredFirstNameIsValid,
            lastName: enteredLastNameIsValid,
        })

        const formIsValid = enteredEmailisValid && enteredPhoneIsValid && enteredFirstNameIsValid && enteredLastNameIsValid

        if (formIsValid) {
            setIsLoading(true)
            const reqData = {
                email: enteredEmail,
                phone: enteredPhone,
                first_name: enteredFirstName,
                last_name: enteredLastName,
            }
            const user = await updateUserInfo(token, reqData)

            if (user.message) {

                dispatch(variousActions.setError(user))
            } else {
                
                dispatch(variousActions.setError({ message: 'Account successfully updated!', type: 'success'}))
                dispatch(authActions.setUser(user))
                localStorage.setItem("currentUser", JSON.stringify(user)); 
            }
            setIsLoading(false)
        } 

    }
   

    return  <form onSubmit={signUpHandler} className='form-fields-container'>       
        <div className="form-controller">
            <label className="label" htmlFor="email_input">Email</label>
            <input type="text" id="email_input" placeholder="Email" required ref={emailInputRef} className={`${!formInputsValidity.email ? 'error' : null}`} defaultValue={user.email}/>
            {!formInputsValidity.email && <div className='error-message'>Please enter a valid email!</div>}
        </div>
        <div className="form-controller">
            <label className="label" htmlFor="phone_input">Phone</label>
            <input type="text" id="phone_input" placeholder="Phone" required ref={phoneInputRef} className={`${!formInputsValidity.phone ? 'error' : null}`} defaultValue={user.phone}/>
            {!formInputsValidity.phone && <div className='error-message'>Please enter a phone number!</div>}
        </div>
        <div className="form-controller">
            <label className="label" htmlFor="first_name_input">First Name</label>
            <input type="text" id="first_name_input" placeholder="First Name" required ref={firstNameInputRef} className={`${!formInputsValidity.firstName ? 'error' : null}`} defaultValue={user.first_name}/>
            {!formInputsValidity.firstName && <div className='error-message'>Please enter a name!</div>}
        </div>
        <div className="form-controller">
            <label className="label" htmlFor="last_name_input">Last Name</label>
            <input type="text" id="last_name_input" placeholder="Last Name" required ref={lastNameInputRef} className={`${!formInputsValidity.lastName ? 'error' : null}`} defaultValue={user.last_name}/>
            {!formInputsValidity.lastName && <div className='error-message'>Please enter a name!</div>}
        </div>
        <div className="form-controller">
            {!isLoading && <button className="btn">Update account</button>}
            {isLoading && <span className="btn"><span className="loader"></span></span>}
        </div>
    </form>
}

export default EditAccount