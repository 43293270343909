const PenIcon = ({stroke}) => {
    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4414_1316)">
            <path d="M4.93443 16.6475L7.41943 19.1122L22.2076 4.20213L19.7226 1.73743L4.93443 16.6475Z"
                stroke={stroke ? stroke : '#242C37'} strokeWidth="2" strokeLinejoin="round" />
            <path d="M2.47991 21.6073L7.41946 19.1122L4.93446 16.6475L2.47991 21.6073Z" stroke={stroke ? stroke : '#242C37'}
                strokeWidth="2" strokeLinejoin="round" />
            <path d="M19.7429 6.68713L17.2579 4.22243" stroke={stroke ? stroke : '#242C37'} strokeWidth="2"
                strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_4414_1316">
                <rect width="5.25" height="28" fill="white"
                    transform="translate(3.72754 24.0771) rotate(-135.235)" />
            </clipPath>
        </defs>
    </svg>
}

export default PenIcon