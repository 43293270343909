// import { useState } from "react"

const IndividualImageBuilder = ({img, imagesAreLoaded, imgIndex}) => {
    // const [loaded, setLoaded] = useState(false)
    // imagesAreLoaded(false)
    const onLoad = () => {
        // imagesAreLoaded(true)

        console.log('imgIndex::: ', imgIndex)
    }


    return <>
        <img src={img.uri} alt="ceva" style={{filter: img.filter ? img.filter : ''}} onLoad={onLoad}/>
    </>
}

export default IndividualImageBuilder