import { useSelector } from 'react-redux';
import CompleteBadgeiconIcon from '../../../icons/completebadgeicon';

const Badge = ({id}) => {
    // const productChoices = useSelector(state => state.product.product)

    // const foundProductMarbleChoice = productChoices.find(item => item.choice_id == id)
    return <>
        {/* {foundProductMarbleChoice && foundProductMarbleChoice.selected && <CompleteBadgeiconIcon />} */}
        <CompleteBadgeiconIcon />
    </>
}

export default Badge