import classes from './Category.module.css'
import { useRef, useEffect, useState } from 'react';
import { CSSTransition } from "react-transition-group";
import SubCategoryHeaderNav from './SubCategoryHeaderNav';

const SubCategoryHeader = ({item}) => {
   
    const innerMenuRef = useRef(null);
    const nodeRef = useRef(null)
    const [activeID, setActiveID] = useState('');
    const [topBar, setTopBar] = useState(false);

    const isSticky = (e) => {
        const categories_header = innerMenuRef.current;
        
        if (categories_header != undefined && categories_header != null) {
            if (categories_header.getBoundingClientRect().bottom < 180) {
                setTopBar(true);
            } else {
                setTopBar(false);
            }
        }
        
    }; 
    const elementInView = () => {
        const elements = document.querySelectorAll('.sub_categories_item')

        elements.forEach(element => {
            element.classList.remove('active')
            if (element.getBoundingClientRect().top < 351) {
                element.classList.add('active')

                setActiveID(element.id)
            }
        });
    }

    const handleClickScroll = (id) => {

        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        window.addEventListener("scroll", elementInView);
       
        return () => {
            window.removeEventListener("scroll", isSticky);
            window.removeEventListener("scroll", elementInView);
        };
    }, []);

    

    return <>
        <div className={`${classes.categories_controller_container} categories_controller_container`} ref={innerMenuRef}>
            <SubCategoryHeaderNav item={item} handleClickScroll={handleClickScroll} activeID={activeID} flag={topBar}/>
        </div>    
        <CSSTransition 
        in={topBar} 
        nodeRef={nodeRef} 
        timeout={500}
        classNames="fixed-bar"
        unmountOnExit>
            <div className={`${classes.categories_controller_container} ${classes.categories_controller_container_fixed} categories_controller_container_fixed`} ref={nodeRef}>
                <SubCategoryHeaderNav item={item} handleClickScroll={handleClickScroll} activeID={activeID} flag={topBar}/>
            </div>
        </CSSTransition>   
    </>
}

export default SubCategoryHeader