import { useEffect, useRef, useState } from 'react';
import classes from './SpecialInstructionsBuilder.module.css'
import { useSelector } from 'react-redux';

const CustomDesign = ({text}) => {
    const textareaRef = useRef(null);
    const [textareaValue, setTextareaValue] = useState(text)

    // const editOptions = useSelector(state => state.productDetails.edit)
    // const pageReload = useSelector(state => state.productDetails.pageReload)

    // const [isActive, setIsActive] = useState(false)

    // const changeStateHandler = (event) => {
    //     setIsActive(event.target.checked)
    // }

    // useEffect(() => {
    //     if (editOptions) {
    //         if (editOptions.specialinstructions) {
    //             let cM = editOptions.specialinstructions.split(" | ")[1]
    //             if (cM) {
    //                 console.log('cM.split("Custom Message: ")::::: ', cM.split("Custom Design: "))
    //                 let message = cM.split("Custom Message: ")[1]
    //                 setTextareaValue(message)
    //             }
    //         }
    //     } 
    //     // return () => {
    //     //     dispatch(productDetailsActions.setEditOptions(null))
    //     // };
    // }, [pageReload])

    return <div className='form-fields-container'>
    <div className={`form-controller ${classes.form_controller}`}>
        <fieldset>
            <legend className='sr-only'>Order Customization</legend>
            {/* <div className={`form-group-checkbox ${classes.form_group}`}>
                <input type="checkbox" id="orderCustomizationTextarea" name="orderCustomizationTextarea" onChange={changeStateHandler} value={isActive} checked={isActive}/>
                <label htmlFor="orderCustomizationTextarea">special instructions (<span>optional</span>)</label>
            </div> */}
            
            <div className={classes.form_group}>
                <label htmlFor='orderCustomDesignTextareaInput' className={classes.label_title}>CUSTOM DESIGN instructions (<span>+$5.00</span>)</label>
                <textarea ref={textareaRef} id='orderCustomDesignTextareaInput' name="orderCustomDesignTextareaInput" placeholder='Write any custom text here...' defaultValue={textareaValue}></textarea>
                <span className={classes.instructions}>Enter any special instructions here, such as a custom design description. No price altering substitutions/additions. </span>
            </div>                                
        </fieldset>
    </div>
</div>
}

export default CustomDesign